import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Compressor from "compressorjs";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { useImageCacheManager } from "./ImageCachingComponent";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
export const compress = async (
  file: File,
  quality: number,
  maxHeight: number | undefined,
  maxWidth: number | undefined
  // convertSize?: number
): Promise<File | Blob> => {
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: quality,
      maxHeight,
      maxWidth,
      // convertSize,
      success(result) {
        resolve(result);
      },
      error(error) {
        reject(error);
      },
    });
  });
};

export const UploadImageComponent = ({
  profile,
  size = "250px",
  onUpload,
  supabase,
  beforeUpload = () => {},
  photoFileName,
  originalFileURL,
  onDeletePhoto,
}: {
  profile: any;
  photoFileName: string;
  beforeUpload?: any;
  size?: string;
  onUpload: any; //(url: string) => void;
  supabase: any;
  onDeletePhoto: any;
  originalFileURL: string;
}) => {
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { getImageCacheManagerValue, updateCacheObject } =
    useImageCacheManager();
  const supabasePublicUrl =
    "https://bkaqugwsgyvokszdjwoz.supabase.co/storage/v1/object/public/userPhotos/";

  useEffect(() => {
    const imageCacheReference = getImageCacheManagerValue(photoFileName);
    if (originalFileURL) {
      if (imageCacheReference) {
        setPreviewImageUrl(
          `${supabasePublicUrl}${originalFileURL}?cacheset=${imageCacheReference}`
        );
      } else {
        setPreviewImageUrl(`${supabasePublicUrl}${originalFileURL}`);
      }
    }
  }, [profile]);

  async function UploadPhoto(event: any) {
    await beforeUpload();
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];
      const fileExt = file.name.split(".").pop();
      const filePath = `${profile.id}/${photoFileName}.${fileExt}`;

      const compressedFile =
        (await file.size) < 25000
          ? file
          : await compress(file, 0.6, 400, undefined);
      console.log(file);
      console.log(compressedFile);
      const { error: uploadError } = await supabase.storage
        .from("userPhotos")
        .upload(filePath, compressedFile);

      if (uploadError) {
        if (uploadError?.error === "Duplicate") {
          const { data, error } = await supabase.storage
            .from("userPhotos")
            .remove([filePath]);

          const { error: uploadError } = await supabase.storage
            .from("userPhotos")
            .upload(filePath, compressedFile);
        } else {
          throw uploadError;
        }
      }

      if (!uploadError) {
        // need to clean up if file extension changes
        const oldFile = originalFileURL;
        const oldFileExtension = oldFile.split(".").pop();
        if (fileExt !== oldFileExtension) {
          const { data, error } = await supabase.storage
            .from("userPhotos")
            .remove([originalFileURL]);
        }
      }
      // @ts-ignore
      onUpload(event, filePath);
      updateCacheObject(photoFileName);
    } catch (error) {
      // @ts-ignore
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  async function deletePhoto() {
    try {
      setDeleting(true);
      const { error: deleteError } = await supabase.storage
        .from("userPhotos")
        .remove(originalFileURL);

      if (deleteError) {
        throw deleteError;
      }
      onDeletePhoto();
    } catch (error) {
      // @ts-ignore
      alert(error.message);
    } finally {
      setDeleting(false);
    }
  }

  return (
    <div>
      {previewImageUrl && originalFileURL !== "" ? (
        <>
          {uploading ? (
            <CircularProgress />
          ) : (
            <img
              src={previewImageUrl}
              alt="Preview Image"
              className="imageUpload image"
              style={{ height: size }}
            />
          )}
        </>
      ) : (
        <div
          className="imageUpload no-image"
          style={{ height: size, width: "140px" }}
        >
          <ImageNotSupportedIcon
            style={{ color: "#E6E6E6", fontSize: "80px" }}
          />
        </div>
      )}
      <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
        <Button variant="outlined">
          <label
            className="button primary block"
            htmlFor="single"
            style={{
              cursor: "pointer",
            }}
          >
            {uploading ? "Uploading ..." : "Upload Image"}
          </label>
          <input
            style={{
              visibility: "hidden",
              position: "absolute",
            }}
            type="file"
            id="single"
            accept="image/*"
            onChange={UploadPhoto}
            disabled={uploading}
          />
        </Button>
        {originalFileURL !== "" && originalFileURL && (
          <Button onClick={deletePhoto} variant="outlined" color="error">
            {deleting ? "Deleting..." : <DeleteOutlinedIcon />}
          </Button>
        )}
      </div>
    </div>
  );
};
