import { useEffect, useState } from "react";

export const useImageCacheManager = () => {
  const [imageCacheManagerObject, setImageCacheManagerObject] = useState({});

  useEffect(() => {
    // When the component mounts, check if there's already an imageChacheManager
    const storedObject = localStorage.getItem("imageCacheManager");
    if (storedObject) {
      setImageCacheManagerObject(JSON.parse(storedObject));
    }
  }, []);

  const updateCacheObject = (key: string) => {
    const newCacheManagerObject = { ...imageCacheManagerObject };
    //@ts-ignore
    newCacheManagerObject[key] = Math.floor(Math.random() * 10000);
    setImageCacheManagerObject(newCacheManagerObject);
    localStorage.setItem(
      "imageCacheManager",
      JSON.stringify(newCacheManagerObject)
    );
  };

  const getImageCacheManagerValue = (key: string) => {
    const storedObject = localStorage.getItem("imageCacheManager");
    if (storedObject) {
      //@ts-ignore
      return JSON.parse(storedObject)[key];
    }
    return null;
  };

  const getImageCacheManager = () => {
    const storedObject = localStorage.getItem("imageCacheManager");
    if (storedObject) {
      //@ts-ignore
      return storedObject;
    }
    return {};
  };
  return {
    imageCacheManagerObject,
    getImageCacheManagerValue,
    getImageCacheManager,
    updateCacheObject,
  };
};
