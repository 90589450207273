export const websiteChangeTemplateDBUpdate = async (
  supabase: any, // the supabase client
  template: any, // current template selected
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the primary color has changed
  if (!(template === profile.website_template) && template.length >= 2) {
    const { error } = await supabase
      .from("profiles")
      .update({ website_template: template })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const websitePrimaryColorDBUpdate = async (
  supabase: any, // the supabase client
  primaryColor: any, //the hex code of the primary color
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the primary color has changed
  if (
    !(primaryColor === profile.website_primary_color) &&
    primaryColor.length >= 2
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ website_primary_color: primaryColor })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const websiteSecondaryColorDBUpdate = async (
  supabase: any, // the supabase client
  secondaryColor: any, //the hex code of the primary color
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the primary color has changed
  if (
    !(secondaryColor === profile.website_secondary_color) &&
    secondaryColor.length >= 2
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ website_secondary_color: secondaryColor })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const websiteOnHoverColorDBUpdate = async (
  supabase: any, // the supabase client
  hoverColor: any, //the hex code of the primary color
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the primary color has changed
  if (
    !(hoverColor === profile.website_on_hover_color) &&
    hoverColor.length >= 2
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ website_on_hover_color: hoverColor })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const businessNameDBUpdate = async (
  supabase: any, // the supabase client
  businessName: any, //the business name string
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the business name has changed
  if (!(businessName === profile.business_name) && businessName.length >= 2) {
    const { error } = await supabase
      .from("profiles")
      .update({ business_name: businessName })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const aboutBodyInfoDBUpdate = async (
  supabase: any, // the supabase client
  aboutBodyText: any, //the about body string
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the about body has changed
  if (!(aboutBodyText === profile.about_body_text)) {
    const { error } = await supabase
      .from("profiles")
      .update({ about_body_text: aboutBodyText })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const aboutSummaryInfoDBUpdate = async (
  supabase: any, // the supabase client
  aboutSummaryText: any, //the about summary string
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the about body has changed
  if (!(aboutSummaryText === profile.about_summary_text)) {
    const { error } = await supabase
      .from("profiles")
      .update({ about_summary_text: aboutSummaryText })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const userFirstDashboardLoadDBUpdate = async (
  supabase: any, // the supabase client
  userFirstDashboaradLoad: any, //the first time load boolean
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the status has changed
  if (!(userFirstDashboaradLoad === profile.user_first_dashboard_load)) {
    const { error } = await supabase
      .from("profiles")
      .update({ user_first_dashboard_load: userFirstDashboaradLoad })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const userWantsLeadGenCallDBUpdate = async (
  supabase: any, // the supabase client
  userWantsLeadGen: any, //the user is requesting lead gen call boolean
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only update if status changes for lead gen
  if (!(userWantsLeadGen === profile.user_wants_lead_gen)) {
    const { error } = await supabase
      .from("profiles")
      .update({ user_wants_lead_gen: userWantsLeadGen })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const aboutCallToActionDBUpdate = async (
  supabase: any, // the supabase client
  aboutCallToAction: any, //the about call to action string
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the about call to action has changed
  if (!(aboutCallToAction === profile.about_call_to_action)) {
    const { error } = await supabase
      .from("profiles")
      .update({ about_call_to_action: aboutCallToAction })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const businessSloganDBUpdate = async (
  supabase: any, // the supabase client
  businessSlogan: any, //the business slogan string
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the business name has changed
  if (!(businessSlogan === profile.business_name)) {
    const { error } = await supabase
      .from("profiles")
      .update({ business_slogan: businessSlogan })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const servicesCallToActionDBUpdate = async (
  supabase: any, // the supabase client
  servicesCallToAction: any, //the services call to action text
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the primary color has changed
  if (!(servicesCallToAction === profile.services_call_to_action)) {
    const { error } = await supabase
      .from("profiles")
      .update({ services_call_to_action: servicesCallToAction })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const serviceAreaCallToActionDBUpdate = async (
  supabase: any, // the supabase client
  serviceAreaCallToAction: any, //the service area call to action text
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the primary color has changed
  if (!(serviceAreaCallToAction === profile.service_area_call_to_action)) {
    const { error } = await supabase
      .from("profiles")
      .update({ service_area_call_to_action: serviceAreaCallToAction })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const websiteLastUpdatedAtDBUpdate = async (
  supabase: any, // the supabase client
  currentTime: any, //current time when button is clicked
  profile: any, // current user profile
  setUpdateUserProfile?: any, // update the user profile
  updateUserProfile?: number // value that updates the profile
) => {
  const { error } = await supabase
    .from("profiles")
    .update({ user_website_last_deployed: currentTime })
    .eq("id", profile.id);
  if (setUpdateUserProfile && updateUserProfile) {
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const contactCallToActionDBUpdate = async (
  supabase: any, // the supabase client
  contactCallToAction: any, //the contact call to action text
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the primary color has changed
  if (!(contactCallToAction === profile.contact_call_to_action)) {
    const { error } = await supabase
      .from("profiles")
      .update({ contact_call_to_action: contactCallToAction })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const userWantsWebsiteReviewDBUpdate = async (
  supabase: any, // the supabase client
  wantsServiceReview: boolean, //the contact call to action text
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if service setting changed
  if (!(wantsServiceReview === profile.user_wants_website_review)) {
    const { error } = await supabase
      .from("profiles")
      .update({
        user_wants_website_review: wantsServiceReview,
      })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};
