import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export type BusinessAddress = {
  street: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
  title: string;
  description: string;
  locationPhone: string;
  showAddress: boolean;
};

export const DefaultBusinessAddressObject = {
  street: "",
  unit: "",
  city: "",
  state: "",
  zip: "",
  title: "",
  description: "",
  locationPhone: "",
  showAddress: true,
};

export const statesList = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const businessAddressesDBUpdate = async (
  supabase: any, // the supabase client
  businessAddresses: any, //the businessAddresses state object
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if business address changes
  if (
    !(
      JSON.stringify(profile?.business_addresses) ===
      JSON.stringify(businessAddresses)
    )
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ business_addresses: businessAddresses })
      .eq("id", profile.id);
    console.log(error);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const BusinessAddressForm = ({
  businessAddresses,
  setBusinessAddresses,
}: {
  businessAddresses: any;
  setBusinessAddresses: any;
}) => {
  const changeInputField = (
    listIndex: number,
    fieldKey: string,
    onChangeValue: string | boolean
  ) => {
    const businessAddressesCopy = [...businessAddresses];
    businessAddressesCopy[listIndex] = {
      ...businessAddressesCopy[listIndex],
      [fieldKey]: onChangeValue,
    };
    setBusinessAddresses(businessAddressesCopy);
  };

  const AddLocation = () => {
    // need to add a new business to the list
    setBusinessAddresses([
      ...businessAddresses,
      { ...DefaultBusinessAddressObject },
    ]);
  };

  const OnRemoveLocation = (listIndex: number) => {
    const businessAddressesCopy = [...businessAddresses];
    businessAddressesCopy.splice(listIndex, 1);
    setBusinessAddresses(businessAddressesCopy);
  };

  const addressBuilderForm = (listIndex: number) => {
    // showAddress: true,
    return (
      <Grid
        style={{ maxWidth: "600px", paddingTop: "10px" }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography
            className={"first-time-login-page-input-title"}
            gutterBottom
            align="left"
          >
            Street
          </Typography>
          <TextField
            fullWidth
            id={`business-street-${listIndex}`}
            placeholder="123 Business Street"
            value={businessAddresses[listIndex].street}
            onChange={(e) => {
              changeInputField(listIndex, "street", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={"first-time-login-page-input-title"}
            gutterBottom
            align="left"
          >
            Street 2
          </Typography>
          <TextField
            fullWidth
            id={`business-street-${listIndex}`}
            placeholder="Unit, Suite, etc (optional)"
            value={businessAddresses[listIndex].unit}
            onChange={(e) => {
              changeInputField(listIndex, "unit", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={"first-time-login-page-input-title"}
            gutterBottom
            align="left"
          >
            City
          </Typography>
          <TextField
            fullWidth
            id={`business-city-${listIndex}`}
            placeholder="City"
            value={businessAddresses[listIndex].city}
            onChange={(e) => {
              changeInputField(listIndex, "city", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            className={"first-time-login-page-input-title"}
            gutterBottom
            align="left"
          >
            State
          </Typography>
          <TextField
            fullWidth
            select
            id={`business-state-${listIndex}`}
            label="State"
            value={businessAddresses[listIndex].state}
            onChange={(e) => {
              changeInputField(listIndex, "state", e.target.value);
            }}
          >
            {statesList.map((value: any) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Typography
            className={"first-time-login-page-input-title"}
            gutterBottom
            align="left"
          >
            Zipcode
          </Typography>
          <TextField
            fullWidth
            id={`business-zipcode-${listIndex}`}
            placeholder="Zipcode"
            value={businessAddresses[listIndex].zip}
            onChange={(e) => {
              changeInputField(listIndex, "zip", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={"first-time-login-page-input-title"}
            gutterBottom
            align="left"
          >
            Location Description
          </Typography>
          <TextField
            fullWidth
            id={`business-location-description-${listIndex}`}
            placeholder="Location Description (optional)"
            value={businessAddresses[listIndex].description}
            onChange={(e) => {
              changeInputField(listIndex, "description", e.target.value);
            }}
          />
        </Grid>
        {(businessAddresses.length > 1 ||
          businessAddresses[listIndex].title.length > 1) && (
          <Grid item xs={12}>
            <Typography
              className={"first-time-login-page-input-title"}
              gutterBottom
              align="left"
            >
              Location Title
            </Typography>
            <TextField
              fullWidth
              id={`business-title-${listIndex}`}
              placeholder="Business Title (optional)"
              value={businessAddresses[listIndex].title}
              onChange={(e) => {
                changeInputField(listIndex, "title", e.target.value);
              }}
            />
          </Grid>
        )}
        {(businessAddresses.length > 1 ||
          businessAddresses[listIndex].locationPhone.length > 1) && (
          <Grid item xs={12}>
            <Typography
              className={"first-time-login-page-input-title"}
              gutterBottom
              align="left"
            >
              Location Specific Phone
            </Typography>
            <TextField
              fullWidth
              id={`business-location-phone-${listIndex}`}
              placeholder="Location Specific Phone (optional)"
              value={businessAddresses[listIndex].locationPhone}
              onChange={(e) => {
                changeInputField(listIndex, "locationPhone", e.target.value);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} style={{ display: "flex" }}>
          <Checkbox
            checked={!businessAddresses[listIndex].showAddress}
            onChange={() => {
              changeInputField(
                listIndex,
                "showAddress",
                !businessAddresses[listIndex].showAddress
              );
            }}
          />
          <p>Just Display This Locations Description</p>
        </Grid>
        {businessAddresses.length > 1 && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => {
                OnRemoveLocation(listIndex);
              }}
            >
              <DeleteOutlineIcon style={{ fontSize: "16px" }} />
              remove location
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const addressAccordianRenderer = () => (
    <>
      {businessAddresses.map((values: any, index: number) => (
        <Accordion
          key={index}
          defaultExpanded={index + 1 >= businessAddresses.length}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Business Address {index + 1}
          </AccordionSummary>
          <AccordionDetails>{addressBuilderForm(index)}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );

  return (
    <>
      {" "}
      {businessAddresses.length > 1 && addressAccordianRenderer()}
      {businessAddresses.length <= 1 && addressBuilderForm(0)}
      <Button
        variant="outlined"
        sx={{ maxWidth: "180px", marginTop: "30px", marginBottom: "80px" }}
        onClick={AddLocation}
        disabled={businessAddresses.length >= 3}
      >
        <AddIcon style={{ fontSize: "16px" }} /> add location
      </Button>
    </>
  );
};
