import {
  ServiceAreasForm,
  serviceAreaDBUpdate,
} from "../../SharedComponents/BusinessServiceAreas";
import supabaseClient from "../../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { UploadImageComponent } from "../../SharedComponents/ImageComponents/UploadImageComponent";
import {
  servicesCallToActionDBUpdate,
  serviceAreaCallToActionDBUpdate,
} from "../../SharedComponents/SingleFieldDBUpdateFunctions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { useState } from "react";
import {
  BusinessServicesDBUpdate,
  BusinessServices,
  BusinessServicesForm,
  DefaultBusinessServicesObject,
} from "../../SharedComponents/BusinessServices";
import { CardDisplay } from "../components/DashboardPageDisplayComponents";
import { useMediaQuery } from "react-responsive";
import { MobileDashboardBottomBar } from "../components/dashboardNavigation";
export const ServicesPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setOpenPreviewModal,
}: any) => {
  const supabase = supabaseClient();
  const [businessServiceAreas, setBusinessServiceAreas] = useState<
    Array<string>
  >(profile.business_service_areas);

  const [businessServices, setBusinessServices] = useState<
    Array<BusinessServices>
  >(
    profile?.business_services?.length > 0
      ? profile?.business_services
      : [{ ...DefaultBusinessServicesObject }]
  );

  const mobileDashboardView = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const [uploadImageModal, setUploadImageModal] = useState<boolean>(false);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [servicesCallToAction, setServicesCallToAction] = useState<string>(
    profile.services_call_to_action
  );

  const [serviceAreaCallToAction, setServiceAreaCallToAction] =
    useState<string>(profile.service_area_call_to_action);

  const [websiteUpdating, setWebsiteUpdating] = useState<boolean>(false);

  const onSubmit = async () => {
    setWebsiteUpdating(true);
    await serviceAreaDBUpdate(
      supabase,
      businessServiceAreas,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await serviceAreaCallToActionDBUpdate(
      supabase,
      serviceAreaCallToAction,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await BusinessServicesDBUpdate(
      supabase,
      businessServices,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await servicesCallToActionDBUpdate(
      supabase,
      servicesCallToAction,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    setWebsiteUpdating(false);
  };

  const serviceAreas = () => (
    <CardDisplay
      imageSrc={"/assets/serviceAreas.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <div style={{ minWidth: "320px" }}>
        <Typography
          // className={"first-time-login-page-title"}
          variant="h5"
          gutterBottom
          align="left"
        >
          Update Servicing Areas List
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          Add or remove any fields
        </Typography>
        <ServiceAreasForm
          businessServiceAreas={businessServiceAreas}
          setBusinessServiceAreas={setBusinessServiceAreas}
        />
      </div>
    </CardDisplay>
  );

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "320px",
    width: "60%",
    height: "fit-content",
    bgcolor: "background.paper",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    gap: "10px",
    p: 4,
  };

  const servicesModal = () => (
    <Modal
      open={uploadImageModal}
      onClose={() => setUploadImageModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
        >
          <IconButton onClick={() => setUploadImageModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="h5" gutterBottom align="left">
          {businessServices[selectedIndex]?.name} Current Photo
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          If after upload/delete you don't see the photo change please refresh
          the page
        </Typography>
        <UploadImageComponent
          profile={profile}
          originalFileURL={businessServices[selectedIndex]?.photo || ""}
          onDeletePhoto={async (event: any, url: any) => {
            const businessServicesCopy = [...businessServices];
            businessServicesCopy[selectedIndex] = {
              ...businessServicesCopy[selectedIndex],
              photo: "",
            };
            await BusinessServicesDBUpdate(
              supabase,
              businessServicesCopy,
              profile,
              setUpdateUserProfile,
              updateUserProfile
            );
            setBusinessServices(businessServicesCopy);
            setUpdateUserProfile(updateUserProfile + 1);
          }}
          onUpload={async (event: any, url: any) => {
            const businessServicesCopy = [...businessServices];
            businessServicesCopy[selectedIndex] = {
              ...businessServicesCopy[selectedIndex],
              photo: url,
            };
            await BusinessServicesDBUpdate(
              supabase,
              businessServicesCopy,
              profile,
              setUpdateUserProfile,
              updateUserProfile
            );
            setBusinessServices(businessServicesCopy);
            setUpdateUserProfile(updateUserProfile + 1);
          }}
          supabase={supabase}
          photoFileName={`servicePhoto${selectedIndex}`}
        />
      </Box>
    </Modal>
  );

  const services = () => (
    <CardDisplay
      imageSrc={"/assets/serviceExample.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <Typography
        // className={"first-time-login-page-title"}
        variant="h5"
        gutterBottom
        align="left"
      >
        Update/Add/Remove services
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Adding a photo replaces the default one provided
      </Typography>
      <BusinessServicesForm
        businessServices={businessServices}
        setBusinessServices={setBusinessServices}
        canAddImages
        canAddSubServiceInfo
        setUploadImage={() => setUploadImageModal(true)}
        setIndex={setSelectedIndex}
      />
    </CardDisplay>
  );

  const serviceAreaCallToActionForm = () => (
    <CardDisplay
      imageSrc={"/assets/serviceAreaCallToAction.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <div>
        <Typography
          // className={"first-time-login-page-title"}
          variant="h5"
          gutterBottom
          align="left"
        >
          Update/change your service Area call to action
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          Customize your service area text
        </Typography>
        <TextField
          fullWidth
          id="services-call-to-action"
          placeholder="Servicing these areas and more!"
          value={serviceAreaCallToAction}
          onChange={(e) => setServiceAreaCallToAction(e.target.value)}
        />
        <div style={{ paddingTop: "40px" }}></div>
      </div>
    </CardDisplay>
  );

  const servicesCallToActionComponent = () => (
    <CardDisplay
      imageSrc={"/assets/servicesCallToAction.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <div>
        <Typography
          // className={"first-time-login-page-title"}
          variant="h5"
          gutterBottom
          align="left"
        >
          Update/change your services call to action
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          Make the services call to action all your own!
        </Typography>
        <TextField
          fullWidth
          id="services-call-to-action"
          placeholder="Don't see a service you want? Call us and ask!"
          value={servicesCallToAction}
          onChange={(e) => setServicesCallToAction(e.target.value)}
        />
        <div style={{ paddingTop: "40px" }}></div>
      </div>
    </CardDisplay>
  );

  return (
    <>
      <div className="flexColumn">
        {serviceAreas()}
        {serviceAreaCallToActionForm()}
        {servicesCallToActionComponent()}
        {services()}
        {servicesModal()}
      </div>
      <MobileDashboardBottomBar
        onSubmitFunction={onSubmit}
        setOpenPreviewModal={setOpenPreviewModal}
        websiteUpdating={websiteUpdating}
      />
    </>
  );
};
