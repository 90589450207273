import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { popoverClasses } from "@mui/material/Popover";
import ListItemButton from "@mui/material/ListItemButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";
import { LogoutNoModal } from "../../auth/Logout";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import UploadIcon from "@mui/icons-material/Upload";
import { useMediaQuery } from "react-responsive";
import {
  DashboardPreviewContainer,
  sendDataToIframe,
} from "./DashboardPreviewContainer";

export const tabsList = [
  { label: "Business Info", value: "businessInfo" },
  { label: "About Info", value: "aboutInfo" },
  { label: "Services Info", value: "servicesInfo" },
  { label: "Contact Info", value: "contactInfo" },
  { label: "General Site Settings", value: "generalInfo" },
  { label: "Website tools/plugins", value: "toolsPlugins" },
];

export enum TabValues {
  BUSINESS_INFO = "businessInfo",
  ABOUT_INFO = "aboutInfo",
  SERVICE_INFO = "servicesInfo",
  CONTACT_INFO = "contactInfo",
  GENERAL_INFO = "generalInfo",
  TOOLS_PLUGINS = "toolsPlugins",
}

export const UpdateFieldsButton = ({
  onSubmit,
  updateFieldDisabled = false,
  websiteUpdating = false,
}: {
  onSubmit: any;
  updateFieldDisabled?: boolean;
  websiteUpdating?: boolean;
}) => {
  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <Button
        variant="contained"
        sx={{ maxWidth: "300px" }}
        disabled={updateFieldDisabled}
        onClick={() => {
          if (onSubmit) {
            onSubmit();
          }
        }}
      >
        {!websiteUpdating ? "Update Website" : "Updating..."}
      </Button>
    </div>
  );
};

export const MobileDashboardBottomBar = ({
  onSubmitFunction,
  setOpenPreviewModal,
  updateFieldDisabled = false,
  websiteUpdating = false,
}: {
  onSubmitFunction: any;
  setOpenPreviewModal: any;
  updateFieldDisabled?: boolean;
  websiteUpdating?: boolean;
}) => {
  const previewSideBySideView = useMediaQuery({
    query: "(min-width: 740px)",
  });

  const updateButtonText = previewSideBySideView
    ? "Click here to save changes and update the previwer"
    : "Update website/save changes";
  return (
    <>
      <div style={{ height: "64px", minHeight: "64px" }}></div>
      <BottomNavigation
        showLabels
        value={3}
        onChange={(event, newValue) => {
          console.log(newValue);
          if (onSubmitFunction && newValue === 0) {
            onSubmitFunction();
          }
          if (setOpenPreviewModal && newValue === 1) {
            setOpenPreviewModal(1);
          }
        }}
        style={{
          position: "fixed",
          bottom: "0",
          width: "100%",
          // border: "1px #e6e6e6",
          // borderStyle: "solid none none none",
          maxWidth: "100%",
          zIndex: "30",
          backgroundColor: "#045872",
        }}
      >
        <BottomNavigationAction
          disabled={updateFieldDisabled}
          label={!websiteUpdating ? updateButtonText : "Updating..."}
          icon={<UploadIcon />}
          sx={{
            "&:disabled": {
              backgroundColor: "#e6e6e6",
            },
          }}
          style={{
            maxWidth: "100%",
            color: "White",
          }}
        />
        {!previewSideBySideView && (
          <BottomNavigationAction
            label="Preview"
            icon={<AspectRatioIcon />}
            style={{
              maxWidth: "100%",
              color: "White",
            }}
          />
        )}
      </BottomNavigation>
    </>
  );
};

export const DashboardPreviewModal = ({
  open,
  setOpen,
  profile,
}: {
  open: boolean;
  setOpen: any;
  profile: any;
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    borderBox: "in-line",
    height: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    sendDataToIframe(profile);
  }, []);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
      >
        <>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                minHeight: "100%",
              }}
            >
              <DashboardPreviewContainer profile={profile} modalView />
              <BottomNavigation
                showLabels
                value={3}
                onChange={(event, newValue) => {
                  handleClose();
                }}
                style={{
                  position: "absolute",
                  bottom: "32px",
                  width: "100vw",
                  maxWidth: "100%",
                  zIndex: "10000000",
                  backgroundColor: "#045872",
                }}
              >
                <BottomNavigationAction
                  label="Back To Form"
                  icon={<CloseIcon />}
                  style={{
                    maxWidth: "100%",
                    color: "White",
                  }}
                />
              </BottomNavigation>
            </div>
          </Box>
        </>
      </Modal>
    </>
  );
};

export const DashboardHelpModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: any;
}) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    borderBox: "in-line",
    maxWidth: "400px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ padding: "10px 30px 30px 30px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          How can we help?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          A video walkthrough of the dashboard page is coming soon! For now, if
          you have any questions please call us at{" "}
          <Link href={`tel:+1712-249-3290`}>712-249-3290</Link> and we will see
          what we can do to help.
        </Typography>
      </Box>
    </Modal>
  );
};

export function MobileNavigationHamberger({
  setSelectedTab,
  setOpenModal,
}: {
  setSelectedTab: any;
  setOpenModal: any;
}) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List
        style={{ paddingBottom: "0px" }}
        sx={{ [`&.${popoverClasses.root}`]: { pointerEvents: "none" } }}
      >
        <>
          {tabsList.map(({ label, value }) => (
            <ListItem key={value} disablePadding>
              <ListItemButton
                aria-label={`navigattion-button-${value}`}
                onClick={() => {
                  setSelectedTab(value);
                }}
              >
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <ListItemButton
              aria-label={`navigattion-button-$value}`}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <ListItemText primary={"Help"} />
            </ListItemButton>
          </ListItem>
        </>
      </List>
      <List style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <Grid container style={{ paddingTop: "40px", paddingLeft: "15px" }}>
          <LogoutNoModal />
        </Grid>
      </List>
    </Box>
  );

  const MobileHamburger = () => (
    <>
      <IconButton aria-label="hamburger-menu" onClick={() => setOpen(true)}>
        <MenuIcon
          style={{
            color: "white",
            fontSize: "2rem",
          }}
        />
      </IconButton>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        disableScrollLock={true}
      >
        {DrawerList}
      </Drawer>
    </>
  );

  return <MobileHamburger />;
}

export const dashboardTabs = (selectedTab: string, handleTabChange: any) => (
  <>
    <Divider />
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      centered
      style={{ paddingBottom: "10px" }}
    >
      {tabsList.map(({ label, value }) => (
        <Tab key={value} value={value} label={label} />
      ))}
    </Tabs>
  </>
);
