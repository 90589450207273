import { useState, useEffect } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import { Logout } from "../auth/Logout";
import "../App.css";
import { Login } from "../auth/Login";
import { AdminPortal } from "./AdminPortal";
import { DashboardPageWrapper } from "../App";

export const AdminPage = () => {
  const [session, setSession] = useState<any>(null);
  const [profileData, setProfileData] = useState<any>({});
  const supabase = supabaseClient();

  const [gettingUserData, setGettingUserData] = useState<boolean>(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const getUserData = async () => {
    try {
      setGettingUserData(true);
      const { data, error } = await supabase
        .from("profiles")
        .select()
        .eq("id", session.user.id)
        .single();

      setProfileData(JSON.parse(JSON.stringify(data)));
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setGettingUserData(false);
    }
  };

  useEffect(() => {
    if (session && !gettingUserData) {
      getUserData();
    }
  }, [session]);

  if (!session) {
    return (
      <DashboardPageWrapper>
        <Login />
      </DashboardPageWrapper>
    );
  } else if (session && profileData.is_admin) {
    return <AdminPortal />;
  } else {
    return (
      <>
        404{" "}
        <div className="logout-button">
          <Logout />
        </div>
      </>
    );
  }
};
