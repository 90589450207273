import { useState } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import { ScreenPreviewAndFormContainer } from "./components/PageDisplayHelpers";
import {
  phoneAndEmailDBUpdate,
  BusinessEmailAndPhoneForm,
} from "../SharedComponents/BusinessEmailAndPhone";

export const ContactInformationPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
  displayPreviewImage,
}: any) => {
  const [phoneNumber, setPhoneNumber] = useState<string>(
    profile.business_phone_number.length > 1
      ? profile.business_phone_number
      : "+1"
  );
  console.log(phoneNumber);
  const [email, setEmail] = useState<string>(profile.business_email);
  const supabase = supabaseClient();

  const onSubmit = () => {
    phoneAndEmailDBUpdate(
      supabase,
      email,
      phoneNumber,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  return (
    <ScreenPreviewAndFormContainer
      displayPreviewImage={displayPreviewImage}
      pageSelect={pageSelect}
      setPageSelect={setPageSelect}
      onSubmit={onSubmit}
      nextButtonDisabled={phoneNumber === "+1"}
      imageSrc={"/assets/addressHoursContact.png"}
    >
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Help customers contact you!
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Enter your business contact information.
      </Typography>
      <BusinessEmailAndPhoneForm
        phoneNumber={phoneNumber}
        email={email}
        setEmail={setEmail}
        setPhoneNumber={setPhoneNumber}
      />
    </ScreenPreviewAndFormContainer>
  );
};
