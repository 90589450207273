import ConstructionIcon from "@mui/icons-material/Construction";
import Typography from "@mui/material/Typography";

export const PluginsAndToolsPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
}: any) => {
  return (
    <div
      className="flexColumn"
      style={{
        paddingTop: "40px",
        gap: "10px",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <ConstructionIcon style={{ fontSize: "100px", color: "#8692A6" }} />
      <Typography
        variant="h5"
        style={{ color: "#8692A6" }}
        textAlign={"center"}
      >
        Under construction! Please check back every few weeks to see the amazing
        features we are adding! Coming soon: fill out forms, estimator tools,
        reviews generator, sales advertisments, and more!
      </Typography>
    </div>
  );
};
