import {
  SocialMediaLinksType,
  socialMediaLinkDBUpdate,
  SocialMediaLinksForm,
} from "../../SharedComponents/SocialMediaLinks";
import supabaseClient from "../../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import { useState } from "react";
import { contactCallToActionDBUpdate } from "../../SharedComponents/SingleFieldDBUpdateFunctions";
import {
  BusinessEmailAndPhoneForm,
  phoneAndEmailDBUpdate,
} from "../../SharedComponents/BusinessEmailAndPhone";
import { CardDisplay } from "../components/DashboardPageDisplayComponents";
import { useMediaQuery } from "react-responsive";
import { MobileDashboardBottomBar } from "../components/dashboardNavigation";
export const BusinessContactInfoPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setOpenPreviewModal,
}: any) => {
  const [socialMediaLinks, setSocialMediaLinks] =
    useState<SocialMediaLinksType>(profile.social_media_links);
  const supabase = supabaseClient();
  const [contactCallToAction, setContactCallToAction] = useState<string>(
    profile.contact_call_to_action
  );
  const [phoneNumber, setPhoneNumber] = useState<string>(
    profile.business_phone_number
  );
  const [email, setEmail] = useState<string>(profile.business_email);

  const mobileDashboardView = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const [websiteUpdating, setWebsiteUpdating] = useState<boolean>(false);

  const onSubmit = async () => {
    setWebsiteUpdating(true);
    await socialMediaLinkDBUpdate(
      supabase,
      socialMediaLinks,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await phoneAndEmailDBUpdate(
      supabase,
      email,
      phoneNumber,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await contactCallToActionDBUpdate(
      supabase,
      contactCallToAction,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    setWebsiteUpdating(false);
  };

  const phoneAndEmailForm = () => (
    <>
      <Typography
        // className={"first-time-login-page-title"}
        variant="h5"
        gutterBottom
        align="left"
      >
        Update or add your business phone number and email
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Phone number is required, but email is optional
      </Typography>
      <BusinessEmailAndPhoneForm
        phoneNumber={phoneNumber}
        email={email}
        setEmail={setEmail}
        setPhoneNumber={setPhoneNumber}
      />
    </>
  );

  const businessSocialMediaLinksForm = () => (
    <div style={{ minWidth: "320px", paddingBottom: "40px" }}>
      <Typography
        // className={"first-time-login-page-title"}
        variant="h5"
        gutterBottom
        align="left"
      >
        Update or Remove Social Media Links
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Don't forget to post the whole address!
      </Typography>
      <SocialMediaLinksForm
        socialMediaLinks={socialMediaLinks}
        setSocialMediaLinks={setSocialMediaLinks}
      />
    </div>
  );

  const contactCallToActionComponent = () => (
    <div style={{ paddingBottom: "60px" }}>
      <Typography
        // className={"first-time-login-page-title"}
        variant="h5"
        gutterBottom
        align="left"
      >
        Update/change your contacts call to action
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Make the contact us call to action all your own!
      </Typography>
      <TextField
        fullWidth
        id="services-call-to-action"
        placeholder="If you need to reach us after business hours please call"
        value={contactCallToAction}
        onChange={(e) => setContactCallToAction(e.target.value)}
      />
    </div>
  );

  return (
    <>
      <div className="flexColumn">
        <CardDisplay
          imageSrc={"/assets/GetInTouchSection.png"}
          mobileDashboardView={mobileDashboardView}
        >
          {phoneAndEmailForm()}
          {contactCallToActionComponent()}
        </CardDisplay>
        <CardDisplay
          imageSrc={"/assets/GetInTouchSection.png"}
          mobileDashboardView={mobileDashboardView}
        >
          {businessSocialMediaLinksForm()}
        </CardDisplay>
      </div>
      <MobileDashboardBottomBar
        onSubmitFunction={onSubmit}
        setOpenPreviewModal={setOpenPreviewModal}
        updateFieldDisabled={phoneNumber === "+1"}
        websiteUpdating={websiteUpdating}
      />
    </>
  );
};
