import { useState } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { businessNameDBUpdate } from "../SharedComponents/SingleFieldDBUpdateFunctions";
import { ScreenPreviewAndFormContainer } from "./components/PageDisplayHelpers";

export const BusinessNamePage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
  displayPreviewImage,
}: any) => {
  const supabase = supabaseClient();
  const [businessName, setBusinessName] = useState<string>(
    profile.business_name
  );

  const onSubmit = async () => {
    // only submit if the business name has changed
    businessNameDBUpdate(
      supabase,
      businessName,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  return (
    <ScreenPreviewAndFormContainer
      displayPreviewImage={displayPreviewImage}
      pageSelect={pageSelect}
      setPageSelect={setPageSelect}
      onSubmit={onSubmit}
      imageSrc={"/assets/justheader.png"}
      nextButtonDisabled={businessName === ""}
    >
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Boost your business's recognitition
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Enter your business details to get started.
      </Typography>

      <div className="business-name-text-input" style={{ marginTop: "50px" }}>
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          align="left"
        >
          Business Name*
        </Typography>
        <TextField
          type="text"
          fullWidth
          id="busienss-name"
          placeholder="Business Name"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
        />
      </div>
    </ScreenPreviewAndFormContainer>
  );
};
