import { Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
export const BackButton = ({
  pageSelect,
  setPageSelect,
}: {
  pageSelect: number;
  setPageSelect: any;
  onSubmit?: any;
}) => (
  <div style={{ marginLeft: "10px", marginTop: "10px" }}>
    <Button
      onClick={() => {
        setPageSelect(pageSelect - 1);
      }}
      sx={{
        color: "black",
        maxWidth: "100px",
        fontSize: "16px",
        paddingTop: "3px",
      }}
    >
      <ArrowBackIosIcon style={{ fontSize: "16px" }} />
      Back
    </Button>
  </div>
);

export const NextButton = ({
  pageSelect,
  setPageSelect,
  onSubmit,
  disabled = false,
}: {
  pageSelect: number;
  setPageSelect: any;
  onSubmit?: any;
  disabled?: boolean;
}) => (
  <div style={{ display: "flex", gap: "20px" }}>
    {/* <BackButton
      setPageSelect={setPageSelect}
      pageSelect={pageSelect}
      onSubmit={onSubmit}
    /> */}
    <Button
      variant="contained"
      fullWidth
      disabled={disabled}
      // sx={{ maxWidth: "100px", minWidth: "400px" }}
      onClick={() => {
        setPageSelect(pageSelect + 1);
        if (onSubmit) {
          onSubmit();
        }
      }}
    >
      continue
    </Button>
  </div>
);

export const LinearProgressBar = ({ pageSelect }: { pageSelect: number }) => (
  <div className={"page-step-container"}>
    <LinearProgress
      variant="determinate"
      style={{ maxWidth: "600px" }}
      value={(pageSelect - 1) * (100 / 9)}
    />
    <p>Step {pageSelect} of 9</p>
  </div>
);
