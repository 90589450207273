import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export type SocialMediaLinksType = {
  linkedIn: string;
  instagram: string;
  facebook: string;
  whatsApp: string;
  twitter: string;
  googleBusiness: string;
  yelp: string;
};

export const defaultSocialMedia = {
  linkedIn: "",
  instagram: "",
  facebook: "",
  whatsApp: "",
  twitter: "",
  googleBusiness: "",
  yelp: "",
};

export const socialMediaLinkDBUpdate = async (
  supabase: any, // the supabase client
  socialMediaLinks: any, //the social media links object
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if a social media has changed
  // adding comment to upload
  if (
    !(
      JSON.stringify(profile?.social_media_links) ===
      JSON.stringify(socialMediaLinks)
    )
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ social_media_links: socialMediaLinks })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const SocialMediaLinksForm = ({
  setSocialMediaLinks,
  socialMediaLinks,
}: {
  setSocialMediaLinks: any;
  socialMediaLinks: any;
}) => (
  <>
    <Typography
      variant="body1"
      gutterBottom
      align="left"
      style={{ marginTop: "20px" }}
    >
      Instagram
    </Typography>
    <TextField
      type="text"
      fullWidth
      id="instagram"
      placeholder="instagram"
      value={socialMediaLinks.instagram}
      onChange={(e) =>
        setSocialMediaLinks({
          ...socialMediaLinks,
          instagram: e.target.value,
        })
      }
    />

    <Typography
      variant="body1"
      gutterBottom
      align="left"
      style={{ marginTop: "20px" }}
    >
      LinkedIn
    </Typography>
    <TextField
      type="text"
      fullWidth
      id="LinkedIn"
      placeholder="LinkedIn"
      value={socialMediaLinks.linkedIn}
      onChange={(e) =>
        setSocialMediaLinks({ ...socialMediaLinks, linkedIn: e.target.value })
      }
    />
    <Typography
      variant="body1"
      gutterBottom
      align="left"
      style={{ marginTop: "20px" }}
    >
      WhatsApp
    </Typography>
    <TextField
      type="text"
      fullWidth
      id="WhatsApp"
      placeholder="WhatsApp"
      value={socialMediaLinks.whatsApp}
      onChange={(e) =>
        setSocialMediaLinks({ ...socialMediaLinks, whatsApp: e.target.value })
      }
    />

    <Typography
      variant="body1"
      gutterBottom
      align="left"
      style={{ marginTop: "20px" }}
    >
      Twitter/X
    </Typography>
    <TextField
      type="text"
      fullWidth
      id="Twitter/X"
      placeholder="Twitter/X"
      value={socialMediaLinks.twitter}
      onChange={(e) =>
        setSocialMediaLinks({ ...socialMediaLinks, twitter: e.target.value })
      }
    />

    <Typography
      variant="body1"
      gutterBottom
      align="left"
      style={{ marginTop: "20px" }}
    >
      Facebook
    </Typography>
    <TextField
      type="text"
      fullWidth
      id="Facebook"
      placeholder="Facebook"
      value={socialMediaLinks.facebook}
      onChange={(e) =>
        setSocialMediaLinks({ ...socialMediaLinks, facebook: e.target.value })
      }
    />

    <Typography
      variant="body1"
      gutterBottom
      align="left"
      style={{ marginTop: "20px" }}
    >
      Google Business Profile
    </Typography>
    <TextField
      type="text"
      fullWidth
      id="googleBusiness"
      placeholder="Google Business Profile Link"
      value={socialMediaLinks.googleBusiness}
      onChange={(e) =>
        setSocialMediaLinks({
          ...socialMediaLinks,
          googleBusiness: e.target.value,
        })
      }
    />

    <Typography
      variant="body1"
      gutterBottom
      align="left"
      style={{ marginTop: "20px" }}
    >
      Yelp
    </Typography>
    <TextField
      type="text"
      fullWidth
      id="Yelp"
      placeholder="Yelp Page Link"
      value={socialMediaLinks.yelp}
      onChange={(e) =>
        setSocialMediaLinks({
          ...socialMediaLinks,
          yelp: e.target.value,
        })
      }
    />
  </>
);
