import Typography from "@mui/material/Typography";
import { photosObjectDBUpdate } from "../../SharedComponents/MultiFieldDBUpdateFunctions";
import { UploadImageComponent } from "../../SharedComponents/ImageComponents/UploadImageComponent";
import supabaseClient from "../../supabaseClient/supabaseClient";
import { TemplateCarousel } from "./GeneralSiteSettingsComponents";
import { useState } from "react";
import {
  websiteOnHoverColorDBUpdate,
  websitePrimaryColorDBUpdate,
  websiteSecondaryColorDBUpdate,
  websiteChangeTemplateDBUpdate,
} from "../../SharedComponents/SingleFieldDBUpdateFunctions";
import {
  websiteColorColorPicker,
  ServiceAssistancePage,
  LeadGenAssistanceQuestion,
} from "./GeneralSiteSettingsComponents";
import { CardDisplay } from "../components/DashboardPageDisplayComponents";
import { useMediaQuery } from "react-responsive";
import { MobileDashboardBottomBar } from "../components/dashboardNavigation";
export const GeneralSiteSettingsPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setOpenPreviewModal,
}: any) => {
  const supabase = supabaseClient();
  const [websiteMainColor, setWebsiteMainColor] = useState(
    profile.website_primary_color
  );
  const [websiteOnHoverColor, setWebsiteOnHoverColor] = useState(
    profile.website_on_hover_color
  );

  const [websiteSecondaryColor, setWebsiteSecondaryColor] = useState(
    profile.website_secondary_color
  );

  const mobileDashboardView = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const [websiteUpdating, setWebsiteUpdating] = useState<boolean>(false);

  const [currentSelectedTemplate, setCurrentSelectedTemplate] =
    useState<string>(profile.website_template);

  const onSubmit = async () => {
    setWebsiteUpdating(true);
    await websiteOnHoverColorDBUpdate(
      supabase,
      websiteOnHoverColor,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await websiteSecondaryColorDBUpdate(
      supabase,
      websiteSecondaryColor,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await websitePrimaryColorDBUpdate(
      supabase,
      websiteMainColor,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await websiteChangeTemplateDBUpdate(
      supabase,
      currentSelectedTemplate,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    //@ts-ignore
    document.getElementById("inner").src = document.getElementById("inner").src;

    setWebsiteUpdating(false);
  };

  const heroImageSection = () => (
    <CardDisplay
      imageSrc={"/assets/justheader.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <div className="flexColumn">
        <Typography variant="h5" gutterBottom align="left">
          Add or update your websites main hero image.
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          The hero image should be a wide/long photo for the best look. Reach
          out to CRT by phone if you want help modifying your photo with AI to
          fit your site better. If after upload you don't see the below photo
          change please refresh the page.
        </Typography>
        <UploadImageComponent
          profile={profile}
          originalFileURL={profile.user_photos.heroPhoto}
          onDeletePhoto={async () => {
            await photosObjectDBUpdate(
              supabase,
              { ...profile.user_photos, heroPhoto: "" },
              profile,
              setUpdateUserProfile,
              updateUserProfile
            );
            setUpdateUserProfile(updateUserProfile + 1);
          }}
          onUpload={async (event: any, url: any) => {
            await photosObjectDBUpdate(
              supabase,
              { ...profile.user_photos, heroPhoto: url },
              profile,
              setUpdateUserProfile,
              updateUserProfile
            );
            setUpdateUserProfile(updateUserProfile + 1);
          }}
          supabase={supabase}
          photoFileName={"heroPhoto"}
        />
      </div>
    </CardDisplay>
  );

  const websiteAuditSection = () => (
    <CardDisplay mobileDashboardView={mobileDashboardView}>
      <ServiceAssistancePage
        profile={profile}
        setUpdateUserProfile={setUpdateUserProfile}
        updateUserProfile={updateUserProfile}
      />
    </CardDisplay>
  );

  const websiteLeadGenQuestion = () => (
    <CardDisplay mobileDashboardView={mobileDashboardView}>
      <LeadGenAssistanceQuestion
        profile={profile}
        setUpdateUserProfile={setUpdateUserProfile}
        updateUserProfile={updateUserProfile}
      />
    </CardDisplay>
  );

  const websiteTemplateChange = () => (
    <CardDisplay mobileDashboardView={mobileDashboardView}>
      <Typography variant="h5" gutterBottom align="left">
        Change your website template
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        CRT is constantly building new templates, check back later for more
        options. If you save changes and your selected template doesn't show up
        in the previewer please refresh the page.
      </Typography>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <TemplateCarousel
          profile={profile}
          currentSelectedTemplate={currentSelectedTemplate}
          setCurrentSelectedTemplate={setCurrentSelectedTemplate}
        />
      </div>
    </CardDisplay>
  );

  const colorChangeSection = () => (
    <CardDisplay mobileDashboardView={mobileDashboardView}>
      <Typography variant="h5" gutterBottom align="left">
        Change your website color scheme
      </Typography>
      {websiteColorColorPicker(
        websiteMainColor,
        setWebsiteMainColor,
        "main website color",
        profile.website_primary_color
      )}
      {websiteColorColorPicker(
        websiteSecondaryColor,
        setWebsiteSecondaryColor,
        "website secondary color (some templates do not have one)",
        profile.website_secondary_color
      )}
      {websiteColorColorPicker(
        websiteOnHoverColor,
        setWebsiteOnHoverColor,
        "on hover website color",
        profile.website_on_hover_color
      )}
      <div style={{ marginTop: "20px" }}></div>
    </CardDisplay>
  );

  return (
    <>
      {heroImageSection()}
      {websiteTemplateChange()}
      {colorChangeSection()}
      {websiteAuditSection()}
      {websiteLeadGenQuestion()}
      <MobileDashboardBottomBar
        onSubmitFunction={onSubmit}
        setOpenPreviewModal={setOpenPreviewModal}
        websiteUpdating={websiteUpdating}
      />
    </>
  );
};
