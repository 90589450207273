import supabaseClient from "../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { ScreenPreviewAndFormContainer } from "./components/PageDisplayHelpers";
import {
  ServiceAreasForm,
  serviceAreaDBUpdate,
} from "../SharedComponents/BusinessServiceAreas";
export const BusinessServiceAreaPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
  displayPreviewImage,
}: any) => {
  const supabase = supabaseClient();
  const [businessServiceAreas, setBusinessServiceAreas] = useState<
    Array<string>
  >(profile.business_service_areas);
  const onSubmit = () => {
    serviceAreaDBUpdate(
      supabase,
      businessServiceAreas,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  return (
    <ScreenPreviewAndFormContainer
      displayPreviewImage={displayPreviewImage}
      pageSelect={pageSelect}
      setPageSelect={setPageSelect}
      onSubmit={onSubmit}
      imageSrc={"/assets/serviceAreas.png"}
    >
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Let people know your main service areas.
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Enter your usual service areas. These will be displayed in a list. We
        recommend just writing city names, counties, or general areas.
      </Typography>
      <ServiceAreasForm
        businessServiceAreas={businessServiceAreas}
        setBusinessServiceAreas={setBusinessServiceAreas}
      />
    </ScreenPreviewAndFormContainer>
  );
};
