import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button, Switch, Paper, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PreviewImageComponent } from "./ImageComponents/PreviewImageComponent";
export type BusinessServices = {
  name: string;
  price: string;
  oneLiner: string;
  description: string;
  photo: string;
};

export const DefaultBusinessServicesObject = {
  name: "",
  price: "",
  description: "",
  photo: "",
  hasSubDescription: false,
  subDescription: [],
};

export const DefaultSubDescription = {
  header: "",
  body: "",
};

export const BusinessServicesDBUpdate = async (
  supabase: any, // the supabase client
  businessServices: any, //the service state object
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if business services changes
  if (
    !(
      JSON.stringify(profile?.business_services) ===
      JSON.stringify(businessServices)
    )
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ business_services: businessServices })
      .eq("id", profile.id);
    console.log(error);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const BusinessServicesForm = ({
  businessServices,
  setBusinessServices,
  canAddImages,
  canAddSubServiceInfo = false,
  firstTimeAssistanceText = false,
  setUploadImage = () => {},
  setIndex = (e: any) => {},
}: any) => {
  const changeInputField = (
    listIndex: number,
    fieldKey: string,
    onChangeValue: string | boolean | Array<any>
  ) => {
    const businessServicesCopy = [...businessServices];
    businessServicesCopy[listIndex] = {
      ...businessServicesCopy[listIndex],
      [fieldKey]: onChangeValue,
    };

    if (businessServicesCopy[listIndex].hasSubDescription) {
      if (!businessServicesCopy[listIndex].subDescription) {
        businessServicesCopy[listIndex].subDescription = [
          { ...DefaultSubDescription },
        ];
      }
    }
    setBusinessServices(businessServicesCopy);
  };

  const serviceDescriptionPlaceHolder = firstTimeAssistanceText
    ? "Enter a short summary of the service and we will optimize your description and make sure it looks professional."
    : "An in-depth description of your service. A lengthy description helps with web visibility.";

  const AddService = () => {
    // need to add a new business to the list
    setBusinessServices([
      ...businessServices,
      { ...DefaultBusinessServicesObject },
    ]);
  };

  const OnRemoveService = (listIndex: number) => {
    const businessServicesCopy = [...businessServices];
    businessServicesCopy.splice(listIndex, 1);
    setBusinessServices(businessServicesCopy);
  };

  const AddSubServiceDescriptionFields = (listIndex: number) => {
    changeInputField(listIndex, "subDescription", [
      ...businessServices[listIndex].subDescription,
      { ...DefaultSubDescription },
    ]);
  };

  const RemoveSubServiceDescriptionFields = (
    listIndex: number,
    subIndex: number
  ) => {
    const subDescriptionsCopy = [...businessServices[listIndex].subDescription];
    subDescriptionsCopy.splice(subIndex, 1);
    console.log(subDescriptionsCopy);
    changeInputField(listIndex, "subDescription", subDescriptionsCopy);
  };

  const UpdateSubSectionField = (
    listIndex: number,
    subIndex: number,
    fieldKey: string,
    fieldValue: string
  ) => {
    const subDescriptionsCopy = [...businessServices[listIndex].subDescription];
    subDescriptionsCopy[subIndex][fieldKey] = fieldValue;
    changeInputField(listIndex, "subDescription", subDescriptionsCopy);
  };

  const subFieldsForm = (listIndex: any) => {
    if (!businessServices[listIndex].subDescription) {
      return <></>;
    }

    return (
      <Paper
        elevation={3}
        style={{
          padding: "10px",
          borderRadius: "6px",
        }}
      >
        {businessServices[listIndex].subDescription.map(
          (value: any, index: number) => (
            <div
              key={index}
              style={{
                border: "1px solid #e6e6e6",
                padding: "10px",
                borderRadius: "6px",
                marginBottom: "10px",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                align="left"
                className={"first-time-login-page-input-title"}
                style={{ marginTop: "10px" }}
              >
                Sub Header {index + 1}
              </Typography>
              <TextField
                type="text"
                fullWidth
                id={`service-price-${listIndex}`}
                placeholder="Sub header text, leave blank to not display on website"
                value={businessServices[listIndex].subDescription[index].header}
                onChange={(e) => {
                  UpdateSubSectionField(
                    listIndex,
                    index,
                    "header",
                    e.target.value
                  );
                }}
              />
              <Typography
                variant="body1"
                gutterBottom
                align="left"
                className={"first-time-login-page-input-title"}
                style={{ marginTop: "30px" }}
              >
                Sub Body {index + 1}
              </Typography>
              <TextField
                type="text"
                fullWidth
                id={`service-price-${listIndex}`}
                placeholder="Sub body text, leave blank to not display on website"
                value={businessServices[listIndex].subDescription[index].body}
                onChange={(e) => {
                  UpdateSubSectionField(
                    listIndex,
                    index,
                    "body",
                    e.target.value
                  );
                }}
              />
              <div
                className={"service-image-remove-button-container"}
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ marginTop: "20px" }}
                  onClick={() => {
                    RemoveSubServiceDescriptionFields(listIndex, index);
                  }}
                >
                  <DeleteOutlineIcon
                    style={{ fontSize: "16px", paddingRight: "2px" }}
                  />{" "}
                  sub section
                </Button>
              </div>
            </div>
          )
        )}
        <div
          className={"service-image-remove-button-container"}
          style={{
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="outlined"
            sx={{ marginTop: "20px" }}
            onClick={() => {
              AddSubServiceDescriptionFields(listIndex);
            }}
          >
            Add sub section
          </Button>
        </div>
      </Paper>
    );
  };

  const serviceBuilderForm = (listIndex: number) => {
    return (
      <>
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          className={"first-time-login-page-input-title"}
        >
          {" "}
          Service Name{" "}
        </Typography>
        <TextField
          type="text"
          fullWidth
          id={`service-name-${listIndex}`}
          placeholder="Service Name"
          value={businessServices[listIndex].name}
          onChange={(e) => {
            changeInputField(listIndex, "name", e.target.value);
          }}
        />{" "}
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          className={"first-time-login-page-input-title"}
          style={{ marginTop: "30px" }}
        >
          Service Price
        </Typography>
        <TextField
          type="text"
          fullWidth
          id={`service-price-${listIndex}`}
          placeholder="Service Price (ex: call for a quote), 300$ etc"
          value={businessServices[listIndex].price}
          onChange={(e) => {
            changeInputField(listIndex, "price", e.target.value);
          }}
        />
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          className={"first-time-login-page-input-title"}
          style={{ marginTop: "30px" }}
        >
          Service One-Liner
        </Typography>
        <TextField
          fullWidth
          InputProps={{
            rows: 3,
            multiline: true,
            inputComponent: "textarea",
          }}
          id={`service-description-${listIndex}`}
          placeholder={
            "A short one- or two-sentence description/introduction of the service, used to give a preview before the longer text description."
          }
          value={businessServices[listIndex].oneLiner}
          onChange={(e) => {
            changeInputField(listIndex, "oneLiner", e.target.value);
          }}
        />
        <Typography
          variant="body1"
          gutterBottom
          align="left"
          className={"first-time-login-page-input-title"}
          style={{ marginTop: "30px" }}
        >
          Full Service Description
        </Typography>
        <TextField
          fullWidth
          InputProps={{
            rows: 6,
            multiline: true,
            inputComponent: "textarea",
          }}
          id={`service-description-${listIndex}`}
          placeholder={serviceDescriptionPlaceHolder}
          value={businessServices[listIndex].description}
          onChange={(e) => {
            changeInputField(listIndex, "description", e.target.value);
          }}
        />
        {canAddSubServiceInfo && (
          <>
            <div style={{ display: "flex" }}>
              <Typography
                variant="body1"
                gutterBottom
                align="left"
                className={"first-time-login-page-input-title"}
                style={{ marginTop: "30px" }}
              >
                Add sub headers and sub paragraphs
              </Typography>{" "}
              <div style={{ marginTop: "23px" }}>
                <Switch
                  checked={!!businessServices[listIndex].hasSubDescription}
                  onChange={() => {
                    changeInputField(
                      listIndex,
                      "hasSubDescription",
                      !businessServices[listIndex].hasSubDescription
                    );
                  }}
                />
              </div>
            </div>
            {!!businessServices[listIndex].hasSubDescription && (
              <>{subFieldsForm(listIndex)}</>
            )}
          </>
        )}
        {canAddImages && (
          <div
            style={{
              paddingBottom: "20px",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              align="left"
              className={"first-time-login-page-input-title"}
              style={{ marginTop: "30px" }}
            >
              Service Image Preview
            </Typography>{" "}
            <PreviewImageComponent
              profile={Math.random()}
              originalFileURL={businessServices[listIndex].photo}
              photoFileName={
                businessServices[listIndex].photo.length > 1
                  ? businessServices[listIndex].photo
                      .split("/")[1]
                      .split(".")[0]
                  : ""
              }
            />
            <Button
              variant="outlined"
              sx={{ marginTop: "20px" }}
              onClick={() => {
                setUploadImage();
                setIndex(listIndex);
              }}
            >
              Add/update service image
            </Button>
          </div>
        )}
      </>
    );
  };

  const serviceAccordianRenderer = () => (
    <>
      {businessServices.map((values: any, index: number) => (
        <Accordion
          key={index}
          defaultExpanded={index + 1 === businessServices.length}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {businessServices[index].name.length > 1
              ? businessServices[index].name
              : `Business Service ${index + 1}`}
          </AccordionSummary>
          <AccordionDetails>{serviceBuilderForm(index)}</AccordionDetails>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            {businessServices.length > 1 && (
              <Button
                onClick={() => {
                  OnRemoveService(index);
                }}
              >
                <DeleteOutlineIcon
                  style={{ fontSize: "16px", paddingRight: "2px" }}
                />{" "}
                remove{" "}
                {businessServices[index].name.length > 1
                  ? businessServices[index].name
                  : `Business Service ${index + 1}`}
              </Button>
            )}
          </div>
        </Accordion>
      ))}
    </>
  );

  return (
    <>
      {businessServices.length > 1 && serviceAccordianRenderer()}
      {businessServices.length <= 1 && serviceBuilderForm(0)}
      <Button
        variant="outlined"
        sx={{
          maxWidth: "150px",
          marginTop: "20px",
          marginBottom: "60px",
        }}
        onClick={AddService}
      >
        <AddIcon style={{ fontSize: "16px", paddingRight: "2px" }} />
        add service
      </Button>
    </>
  );
};
