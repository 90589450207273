import { useState, useEffect } from "react";
import supabaseClient from "./supabaseClient/supabaseClient";
import "./App.css";
import { Login } from "./auth/Login";
import { Logout } from "./auth/Logout";
import { FirstTimeLoginFlow } from "./FirstTimeLoginFlow/FirstTimeLoginFlow";
import { PaymentsPage } from "./FirstTimeLoginFlow/PaymentsPage";
import { DashboardPage } from "./Dashboard/DashboardPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
export const DashboardPageWrapper = ({ children }: { children: any }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#045872",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        className={"flexCC"}
        style={{
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          minHeight: "100vh",
        }}
      >
        {children}
      </div>
    </ThemeProvider>
  );
};

// #518da6
function App() {
  const [session, setSession] = useState<any>(null);
  const [profileData, setProfileData] = useState<any>({ noData: true });
  const supabase = supabaseClient();
  const [gettingUserData, setGettingUserData] = useState<boolean>(false);
  const navigate = useNavigate();

  let sessionEvent: any = null;

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!sessionEvent) {
        sessionEvent = _event;
        setSession(session);
      } else {
        if (sessionEvent === _event) {
          // do nothing
        } else {
          setSession(session);
          sessionEvent = _event;
        }
      }
      if (sessionEvent === "SIGNED_OUT") {
        sessionEvent = null;
      }
      if (sessionEvent === "PASSWORD_RECOVERY") {
        navigate("/password-recovery");
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const getUserData = async () => {
    try {
      setGettingUserData(true);
      const { data, error } = await supabase
        .from("profiles")
        .select()
        .eq("id", session.user.id)
        .single();

      setProfileData(JSON.parse(JSON.stringify(data)));
      console.log(data);
    } catch (error) {
      console.log(error);
    } finally {
      setGettingUserData(false);
    }
  };

  useEffect(() => {
    if (session && !gettingUserData && profileData.noData) {
      getUserData();
    }
  }, [session]);

  if (!session) {
    return (
      <DashboardPageWrapper>
        <Login />
      </DashboardPageWrapper>
    );
  } else {
    return (
      <DashboardPageWrapper>
        {session && (
          <>
            {!profileData.noData && (
              <>
                {!profileData.completed_first_time_login_flow && (
                  <>
                    <FirstTimeLoginFlow profile={profileData} />
                    <div className="logout-button">
                      <Logout />
                    </div>
                  </>
                )}
                {profileData.completed_first_time_login_flow &&
                  profileData.initial_payment_made && (
                    <DashboardPage profile={profileData} />
                  )}
                {profileData.completed_first_time_login_flow &&
                  !profileData.initial_payment_made && (
                    <>
                      <PaymentsPage profile={profileData} />{" "}
                      <div className="logout-button">
                        <Logout />
                      </div>
                    </>
                  )}
              </>
            )}
          </>
        )}
      </DashboardPageWrapper>
    );
  }
}

export default App;
