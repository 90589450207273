import { NextButton } from "./components/pageUpdateHelpers";
import { useState } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import {
  SocialMediaLinksType,
  socialMediaLinkDBUpdate,
  SocialMediaLinksForm,
} from "../SharedComponents/SocialMediaLinks";
import { ScreenPreviewAndFormContainer } from "./components/PageDisplayHelpers";

export const BusinessSocialMedaiLinksPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
  displayPreviewImage,
}: any) => {
  const [socialMediaLinks, setSocialMediaLinks] =
    useState<SocialMediaLinksType>(profile.social_media_links);
  const supabase = supabaseClient();

  const onSubmit = () => {
    socialMediaLinkDBUpdate(
      supabase,
      socialMediaLinks,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  return (
    <ScreenPreviewAndFormContainer
      displayPreviewImage={displayPreviewImage}
      pageSelect={pageSelect}
      setPageSelect={setPageSelect}
      onSubmit={onSubmit}
      imageSrc={"/assets/addressHoursContact.png"}
    >
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Let people see your online presence
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Enter your social media links (optional)
      </Typography>
      <div style={{ marginBottom: "40px" }}>
        <SocialMediaLinksForm
          socialMediaLinks={socialMediaLinks}
          setSocialMediaLinks={setSocialMediaLinks}
        />
      </div>
    </ScreenPreviewAndFormContainer>
  );
};
