import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const serviceAreaDBUpdate = async (
  supabase: any, // the supabase client
  serviceAreaObject: any, //the service state object
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the service areas change
  if (
    !(
      JSON.stringify(profile?.business_service_areas) ===
      JSON.stringify(serviceAreaObject)
    )
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ business_service_areas: serviceAreaObject })
      .eq("id", profile.id);
    console.log(error);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const ServiceAreasForm = ({
  businessServiceAreas,
  setBusinessServiceAreas,
}: any) => {
  //   useEffect(() => {
  //     console.log(businessServiceAreas);
  //   }, [businessServiceAreas]);

  const changeInputField = (listIndex: number, onChangeValue: string) => {
    const businessServiceAreasCopy = [...businessServiceAreas];
    businessServiceAreasCopy[listIndex] = onChangeValue;
    setBusinessServiceAreas(businessServiceAreasCopy);
  };

  const AddServiceArea = () => {
    setBusinessServiceAreas([...businessServiceAreas, ""]);
  };

  const OnRemoveServiceArea = (listIndex: number) => {
    const businessServiceAreasCopy = [...businessServiceAreas];
    businessServiceAreasCopy.splice(listIndex, 1);
    setBusinessServiceAreas(businessServiceAreasCopy);
  };

  const serviceAreaForm = (listIndex: number) => {
    return (
      <>
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          align="left"
        >
          Service Area {listIndex + 1}
        </Typography>
        <TextField
          type="text"
          style={{ maxWidth: "400px" }}
          fullWidth
          id="service-area"
          placeholder="Service Area"
          value={businessServiceAreas[listIndex]}
          onChange={(e) => {
            changeInputField(listIndex, e.target.value);
          }}
        />
        <Button
          variant="text"
          sx={{
            maxWidth: "250px",
            marginTop: "10px",
            marginBottom: "20px",
          }}
          onClick={() => {
            OnRemoveServiceArea(listIndex);
          }}
        >
          <DeleteOutlineIcon
            style={{ fontSize: "16px", paddingRight: "2px" }}
          />
          remove
        </Button>
      </>
    );
  };

  return (
    <>
      {businessServiceAreas.map((values: any, index: number) => (
        <div key={index}>{serviceAreaForm(index)}</div>
      ))}
      <Button
        variant="outlined"
        sx={{
          maxWidth: "200px",
          marginTop: "10px",
          marginBottom: "80px",
        }}
        onClick={AddServiceArea}
      >
        <AddIcon style={{ fontSize: "16px", paddingRight: "2px" }} /> service
        Area
      </Button>
    </>
  );
};
