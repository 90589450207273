// const { data, error } = await supabase
//   .storage
//   .from('avatars')
//   .download('folder/avatar1.png')

import supabaseClient from "../../supabaseClient/supabaseClient";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import JSZip from "jszip";
import { websiteLastUpdatedAtDBUpdate } from "../../SharedComponents/SingleFieldDBUpdateFunctions";
// @ts-ignore
import { saveAs } from "file-saver";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export const AdminTable = ({
  userData,
  setAdminBackDoorProfile,
}: {
  userData: Array<any>;
  setAdminBackDoorProfile: any;
}) => {
  const supabase = supabaseClient();
  const zip = new JSZip();

  const downloadUserImagesButton = async (
    userId: string,
    userDataObject: any
  ) => {
    const bucket = "userPhotos";
    const folder = userId;

    // Get a list of all the files in the path /my-bucket/images
    const { data: files, error } = await supabase.storage
      .from(bucket)
      .list(folder);

    if (error) {
      throw error;
    }

    // If there are no files in the folder, throw an error
    if (!files || !files.length) {
      alert("No files to download");
    }

    const promises: Array<any> = [];

    // Download each file in the folder
    files.forEach((file) => {
      promises.push(
        supabase.storage.from(bucket).download(`${folder}/${file.name}`)
      );
    });

    // Wait for all the files to download
    const response = await Promise.allSettled(promises);

    // Map the response to an array of objects containing the file name and blob
    const downloadedFiles = response.map((result, index) => {
      if (result.status === "fulfilled") {
        return {
          name: files[index].name,
          blob: result.value.data,
        };
      }
    });

    downloadedFiles.forEach((imgObject) => {
      if (imgObject) {
        zip.file(imgObject.name, imgObject.blob);
      }
    });

    // add their user data object
    zip.file("website_data_json.json", JSON.stringify(userDataObject));

    zip.generateAsync({ type: "blob" }).then(function (content) {
      // see FileSaver.js
      saveAs(content, `userData_${userId}.zip`);
    });
  };

  const UpdateLastUpdateTime = async (userProfile: any) => {
    await websiteLastUpdatedAtDBUpdate(supabase, dayjs(), userProfile);
  };

  const AdminTableMaxWidthStyle = {
    width: 100,
    maxWidth: 100,
    overflowX: "scroll" as "scroll",
    borderStyle: "border-box",
  };

  const DisplayUserdata = () => (
    <>
      <TableContainer component={Paper} style={{ paddingTop: "60px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Customer ID</TableCell>
              <TableCell align="right">Finished+ First Time Flow</TableCell>
              <TableCell align="right">Business Name</TableCell>
              <TableCell align="right">Business Phone</TableCell>
              <TableCell align="right">Business Email</TableCell>
              <TableCell align="right">Initial Payment Made</TableCell>
              <TableCell align="right">Account Last Updated</TableCell>
              <TableCell align="right">Website</TableCell>
              <TableCell align="right">User Wants Website Review</TableCell>
              <TableCell align="right">Website Last Deployed</TableCell>
              <TableCell align="right">Controls</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userData.map((row: any, index: number) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={AdminTableMaxWidthStyle}
                  component="th"
                  scope="row"
                >
                  {row.id}
                </TableCell>
                <TableCell align="right">
                  {row.completed_first_time_login_flow ? "TRUE" : "FALSE"}
                </TableCell>
                <TableCell align="right">{row.business_name}</TableCell>
                <TableCell align="right">{row.business_phone_number}</TableCell>
                <TableCell align="right" style={AdminTableMaxWidthStyle}>
                  {row.business_email}
                </TableCell>
                <TableCell align="right">
                  {row.initial_payment_made ? "TRUE" : "FALSE"}
                </TableCell>
                <TableCell align="right">
                  {" "}
                  {`${dayjs(row.updated_at)
                    // @ts-ignore
                    .utc()
                    .tz("America/Chicago")
                    .format("hh:mm A MM/DD/YYYY")}`}
                </TableCell>
                <TableCell style={AdminTableMaxWidthStyle} align="right">
                  {row.user_website_domain}
                </TableCell>
                <TableCell align="right">
                  {row.user_wants_website_review ? "TRUE" : "FALSE"}
                </TableCell>
                <TableCell align="right">
                  {row.user_website_last_deployed
                    ? `${dayjs(row.user_website_last_deployed)
                        // @ts-ignore
                        .utc()
                        .tz("America/Chicago")
                        .format("hh:mm A MM/DD/YYYY")}`
                    : "not deployed yet"}
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{ width: "115px" }}
                      onClick={() => {
                        downloadUserImagesButton(row.id, row);
                      }}
                    >
                      download
                    </Button>
                    <Button
                      variant="contained"
                      style={{ width: "115px" }}
                      onClick={() => {
                        setAdminBackDoorProfile(row);
                      }}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      style={{ width: "115px" }}
                      onClick={() => {
                        UpdateLastUpdateTime(row);
                      }}
                    >
                      Update Deploy
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
  return <DisplayUserdata />;
};
