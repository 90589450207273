import supabaseClient from "../../supabaseClient/supabaseClient";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  userWantsWebsiteReviewDBUpdate,
  userWantsLeadGenCallDBUpdate,
} from "../../SharedComponents/SingleFieldDBUpdateFunctions";
import { HexColorPicker } from "react-colorful";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../App.css";
import { useState, useEffect } from "react";

export const templateSlideConverter = (template: string) => {
  switch (template) {
    case "templateOne":
      return 0;
    case "templateTwo":
      return 1;
    case "templateThree":
      return 2;
    default:
      return 0;
  }
};

export const slideTemplateConverter = (slide: number) => {
  switch (slide) {
    case 0:
      return "templateOne";
    case 1:
      return "templateTwo";
    case 2:
      return "templateThree";
    default:
      return "templateOne";
  }
};

export const templateToWebsiteViewConverter = (template: string) => {
  switch (template) {
    case "templateOne":
      return "Template One";
    case "templateTwo":
      return "Template Two";
    case "templateThree":
      return "Template Three";
    default:
      return 0;
  }
};

export function TemplateCarousel({
  profile,
  currentSelectedTemplate,
  setCurrentSelectedTemplate,
}: {
  profile: any;
  currentSelectedTemplate: string;
  setCurrentSelectedTemplate: any;
}) {
  const [activeSlide, setActiveSlide] = useState(
    templateSlideConverter(profile.website_template)
  );
  const [sliding, setSliding] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: templateSlideConverter(profile.website_template),
    beforeChange: (current: any, next: any) => {
      setSliding(true);
    },
    afterChange: (current: any) => {
      setActiveSlide(current);
      setSliding(false);
    },
  };
  const slideList = ["templateOne", "templateTwo"];
  return (
    <div
      style={{
        width: "calc(100% - 80px)",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Slider {...settings}>
        {" "}
        {slideList.map((value, index: number) => (
          <div key={index}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "300px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                  }}
                >
                  <Typography>
                    {templateToWebsiteViewConverter(value)}
                  </Typography>
                </div>
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={`/assets/templatePreviews/${value}.png`}
                  alt="CRT Logo"
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <Button
        disabled={sliding}
        onClick={() => {
          setCurrentSelectedTemplate(slideTemplateConverter(activeSlide));
        }}
        variant={"outlined"}
      >
        Select Template
      </Button>
      <Typography variant="body1" gutterBottom align="left">
        {"Currently Using: "}
        {templateToWebsiteViewConverter(profile.website_template)}
      </Typography>
      <Typography variant="body1" gutterBottom align="left">
        {"Currently Selected: "}
        {templateToWebsiteViewConverter(currentSelectedTemplate)}
      </Typography>
    </div>
  );
}

export const ServiceAssistancePage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
}: any) => {
  const supabase = supabaseClient();

  const onSubmit = async (serviceReviewBoolean: boolean) => {
    userWantsWebsiteReviewDBUpdate(
      supabase,
      serviceReviewBoolean,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  const DoYouWantHelpWithServicesQuestion = () => (
    <>
      <Typography variant="body1" gutterBottom align="left">
        <p>
          {profile.user_wants_website_review
            ? "Website is currently being audited please check back later"
            : "Would you like a website audit?"}
        </p>
      </Typography>
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit(true);
          }}
          disabled={profile.user_wants_website_review}
        >
          Audit My Website
        </Button>
        {profile.user_wants_website_review && (
          <Button
            variant="outlined"
            onClick={() => {
              onSubmit(false);
            }}
          >
            Cancel Audit
          </Button>
        )}
      </div>
    </>
  );

  return (
    <div className="flexColumn" style={{ width: "100%", maxWidth: "600px" }}>
      <Typography variant="h5" gutterBottom align="left">
        Would you like a website audit?
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Having issues figuring out what to put in your descriptions? Want help
        making sure your about information, services, and website data is
        optimal to get your site the most clicks? Click the request audit button
        below and someone from CRT will take a look in the next few days and do
        a full audit of your website.
      </Typography>
      {DoYouWantHelpWithServicesQuestion()}
    </div>
  );
};

export const LeadGenAssistanceQuestion = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
}: any) => {
  const supabase = supabaseClient();

  const onSubmit = async (leadGenBoolean: boolean) => {
    userWantsLeadGenCallDBUpdate(
      supabase,
      leadGenBoolean,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  const DoYouWantHelpWithLeadGenQuestion = () => (
    <>
      <Typography variant="body1" gutterBottom align="left">
        <p>
          {profile.user_wants_lead_gen
            ? "CRT will reach out within the next few busienss days"
            : "Would you like CRT to reach out about lead generation options?"}
        </p>
      </Typography>
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit(true);
          }}
          disabled={profile.user_wants_lead_gen}
        >
          Yes Please
        </Button>
        {profile.user_wants_lead_gen && (
          <Button
            variant="outlined"
            onClick={() => {
              onSubmit(false);
            }}
          >
            Cancel Request
          </Button>
        )}
      </div>
    </>
  );

  return (
    <div className="flexColumn" style={{ width: "100%", maxWidth: "600px" }}>
      <Typography variant="h5" gutterBottom align="left">
        Website Lead Generation Consultation
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Looking to get your website out there more? CRT offers several services
        that can help get you to the top of searches, target adds, and much
        more. Click here to have let us know you are interested and we will be
        in touch within the next few business days.
      </Typography>
      {DoYouWantHelpWithLeadGenQuestion()}
    </div>
  );
};

export const websiteColorColorPicker = (
  colorValue: string,
  setColorValue: any,
  colorPickerHeader: string,
  currentWebsiteColor: string
) => (
  <>
    <Typography
      className={"first-time-login-page-subheader"}
      gutterBottom
      align="left"
    >
      {colorPickerHeader}
    </Typography>
    <HexColorPicker color={colorValue} onChange={setColorValue} />

    <div className="color-wheel-value" style={{ borderLeftColor: colorValue }}>
      <Typography align="left" variant="body1" style={{ marginBottom: "0px" }}>
        New selected color is {colorValue}
      </Typography>
    </div>
    <div
      className="color-wheel-value"
      style={{ borderLeftColor: currentWebsiteColor }}
    >
      <Typography align="left" variant="body1" style={{ marginBottom: "0px" }}>
        Current website color is {currentWebsiteColor}
      </Typography>
    </div>
    <Typography variant="body1" gutterBottom align="left">
      Manually enter a color
    </Typography>
    <div style={{ display: "flex", gap: "4px" }}>
      <TextField
        type="text"
        size="small"
        style={{ width: "100px" }}
        id="website-hex-code"
        placeholder="#FFFFFF"
        value={colorValue}
        onChange={(e) => setColorValue(e.target.value)}
      />
      <Button
        variant="outlined"
        sx={{ width: "100px" }}
        onClick={() => {
          setColorValue(currentWebsiteColor);
        }}
      >
        reset
      </Button>
    </div>
  </>
);
