import { useState } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LinearProgressBar } from "./pageUpdateHelpers";
import { NextButton } from "./pageUpdateHelpers";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  paddingTop: "5px",
};

export const PagePreviewDisplay = ({ imageSrc }: { imageSrc: string }) => (
  <div style={{ width: "100%", display: "flex", alignItems: "flex-start" }}>
    <img
      style={{
        width: "100%" /* or any custom size */,
        maxHeight: "80vh",
        objectFit: "contain",
      }}
      src={imageSrc}
      alt="image"
    />
  </div>
);

export const ExampleScreenButton = ({ imageSrc }: { imageSrc: string }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={handleClose}
      >
        <Box sx={style}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "15px",
              backgroundColor: "#045872",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <img
              style={{
                width: "100%" /* or any custom size */,
                objectFit: "contain",
                maxWidth: "100vw",
                maxHeight: "80vh",
              }}
              src={imageSrc}
              alt="image"
            />
            <div
              style={{
                paddingTop: "10px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button onClick={handleClose} variant="outlined">
                Close Preview
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Button
        onClick={handleOpen}
        fullWidth
        variant="text"
        style={{ marginBottom: "20px" }}
      >
        Show Example Screen
      </Button>
    </>
  );
};

export const ScreenPreviewAndFormContainer = ({
  displayPreviewImage,
  pageSelect,
  setPageSelect,
  onSubmit,
  imageSrc,
  children,
  nextButtonDisabled = false,
}: any) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      width: "100%",
    }}
  >
    <div
      className="flexColumn"
      style={{
        width: "100%",
        paddingRight: displayPreviewImage ? "40px" : "0px",
        maxWidth: "600px",
      }}
    >
      <LinearProgressBar pageSelect={pageSelect} />
      {children}
      {!displayPreviewImage && <ExampleScreenButton imageSrc={imageSrc} />}
      <NextButton
        setPageSelect={setPageSelect}
        pageSelect={pageSelect}
        onSubmit={onSubmit}
        disabled={nextButtonDisabled}
      />
    </div>
    {displayPreviewImage && <PagePreviewDisplay imageSrc={imageSrc} />}
  </div>
);

export const MoreInfoOnClick = ({ inputText }: { inputText: string }) => (
  <Tooltip title={<Typography>{inputText}</Typography>}>
    <IconButton style={{ padding: "0px" }}>
      <HelpOutlineIcon />
    </IconButton>
  </Tooltip>
);
