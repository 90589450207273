import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { photosObjectDBUpdate } from "../../SharedComponents/MultiFieldDBUpdateFunctions";
import {
  aboutCallToActionDBUpdate,
  aboutBodyInfoDBUpdate,
  aboutSummaryInfoDBUpdate,
} from "../../SharedComponents/SingleFieldDBUpdateFunctions";
import supabaseClient from "../../supabaseClient/supabaseClient";
import { UploadImageComponent } from "../../SharedComponents/ImageComponents/UploadImageComponent";
import { Divider } from "@mui/material";
import { CardDisplay } from "../components/DashboardPageDisplayComponents";
import { useMediaQuery } from "react-responsive";
import { MobileDashboardBottomBar } from "../components/dashboardNavigation";

export const AboutInfoPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setOpenPreviewModal,
}: any) => {
  const supabase = supabaseClient();
  const [aboutBodyText, setAboutBodyText] = useState<string>(
    profile.about_body_text
  );
  const [aboutSummaryText, setAboutSummaryText] = useState<string>(
    profile.about_summary_text
  );
  const [aboutCallToActionText, setAboutCallToActionText] = useState<string>(
    profile.about_call_to_action
  );
  const mobileDashboardView = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const [websiteUpdating, setWebsiteUpdating] = useState<boolean>(false);

  const onSubmit = async () => {
    setWebsiteUpdating(true);
    await aboutBodyInfoDBUpdate(
      supabase,
      aboutBodyText,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await aboutSummaryInfoDBUpdate(
      supabase,
      aboutSummaryText,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await aboutCallToActionDBUpdate(
      supabase,
      aboutCallToActionText,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    setWebsiteUpdating(false);
  };

  return (
    <>
      <CardDisplay
        imageSrc={"/assets/aboutUsExample.png"}
        mobileDashboardView={mobileDashboardView}
      >
        <Typography
          // className={"first-time-login-page-title"}
          variant="h5"
          gutterBottom
          align="left"
        >
          Add or update your websites about image.
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          If after upload you don't see the below photo change please refresh
          the page to try again
        </Typography>
        <UploadImageComponent
          profile={profile}
          originalFileURL={profile.user_photos.aboutPhoto}
          onUpload={async (event: any, url: any) => {
            await photosObjectDBUpdate(
              supabase,
              { ...profile.user_photos, aboutPhoto: url },
              profile,
              setUpdateUserProfile,
              updateUserProfile
            );
            setUpdateUserProfile(updateUserProfile + 1);
          }}
          onDeletePhoto={async () => {
            await photosObjectDBUpdate(
              supabase,
              { ...profile.user_photos, aboutPhoto: "" },
              profile,
              setUpdateUserProfile,
              updateUserProfile
            );
            setUpdateUserProfile(Math.random());
          }}
          supabase={supabase}
          photoFileName={"aboutPhoto"}
        />
        <div style={{ padding: "20px 0px" }}>
          <Divider />
        </div>
        <Typography variant="h5" gutterBottom align="left">
          Update your about information or add and update your about call to
          action
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          Feel free to leave the call to action blank if you don't want one
        </Typography>
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          align="left"
        >
          About Call To Action Text
        </Typography>
        <TextField
          type="text"
          fullWidth
          id="busienss-about-call-to-action-text"
          placeholder="About call to action."
          value={aboutCallToActionText}
          onChange={(e) => setAboutCallToActionText(e.target.value)}
        />
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          style={{ marginTop: "20px" }}
          align="left"
        >
          About Summary Text
        </Typography>
        <TextField
          type="text"
          fullWidth
          id="busienss-about-summary"
          placeholder="About summary text, try to keep it short (around 400 characters is recommended)"
          multiline
          rows={5}
          value={aboutSummaryText}
          onChange={(e) => setAboutSummaryText(e.target.value)}
        />
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          align="left"
          style={{ marginTop: "20px" }}
        >
          About Body Text
        </Typography>
        <TextField
          type="text"
          fullWidth
          id="busienss-name"
          placeholder="About body text (try to make this several paragraphs)"
          multiline
          rows={10}
          value={aboutBodyText}
          onChange={(e) => setAboutBodyText(e.target.value)}
        />
      </CardDisplay>
      <MobileDashboardBottomBar
        onSubmitFunction={onSubmit}
        setOpenPreviewModal={setOpenPreviewModal}
        websiteUpdating={websiteUpdating}
      />
    </>
  );
};
