import { useState } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import "./Login.css";
import { Checkbox, Link, Modal, Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TOSText } from "./constants/loginConstants";
import { useMediaQuery } from "react-responsive";
import { Turnstile } from "@marsidev/react-turnstile";

enum LoginPages {
  LOGIN_PAGE = "login",
  RECOVER_PASSWORD = "forgotPassword",
  SIGN_UP_PAGE = "signUp",
}

export const Login = () => {
  const theme = useTheme();
  const supabase = supabaseClient();
  const [captchaToken, setCaptchaToken] = useState<any>();

  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmationPassword, setConfirmationPassword] = useState<string>("");
  const [loginPageDisplay, setLoginPageDisplay] = useState<string>(
    LoginPages.LOGIN_PAGE
  );
  const [openTOSModal, setOpenTOSModal] = useState<boolean>(false);
  const [signUpStatusText, setSignUpStatusText] = useState<string | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);
  const [disableLoginAction, setDisableLoginAction] = useState<boolean>(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState<string>("");
  const [sendingAuthInfo, setSendingAuthInfo] = useState<boolean>(false);

  const mobileDashboardView = useMediaQuery({
    query: "(max-width: 450px)",
  });

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    backgroundColor: "white",
    width: "100%",
    boxSizing: "border-box",
    height: "100%",
    maxHeight: mobileDashboardView ? "100%" : "40vh",
    padding: "10px 10px 60px 10px",
  };

  const [termsOfServiceCheckbox, setTermsOfServiceCheckbox] =
    useState<boolean>(false);
  const onLogin = async () => {
    setSendingAuthInfo(true);
    setLoginError(null);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: userName,
        password: password,
        options: { captchaToken },
      });
      if (error) {
        if (error.message === "Email not confirmed") {
          setLoginError(
            "Email not confirmed check your email for verification.  If no email arrives please call CRT Digital (712)-249-3290"
          );
        } else {
          setLoginError(error?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setSendingAuthInfo(false);
  };

  const onResetPassword = async () => {
    setSendingAuthInfo(true);
    const { data, error } = await supabase.auth.resetPasswordForEmail(
      resetPasswordEmail,
      {
        redirectTo: "https://crtdigital.com/password-recovery",
      }
    );
    setDisableLoginAction(true);
    setTimeout(() => {
      setDisableLoginAction(false);
    }, 5000);
    setSendingAuthInfo(false);
  };

  const resetErrorChecking = (currentRecoveryEmail: string) => {
    return (
      !currentRecoveryEmail.includes("@") && currentRecoveryEmail.length > 1
    );
  };

  const onSignUp = async () => {
    setSendingAuthInfo(true);
    setSignUpStatusText(null);
    // Disable the button
    setDisableLoginAction(true);

    // Enable the button after 5 seconds
    setTimeout(() => {
      setDisableLoginAction(false);
    }, 5000);

    const { data, error } = await supabase.auth.signUp({
      email: userName,
      password: password,
      options: { captchaToken },
    });
    if (error) {
      setSignUpStatusText(error?.message);
    } else {
      setSignUpStatusText(
        "Verification Email sent, please check your email to verify your account and login. Verification emails can take time, plaese allow 5 min for your email to show up before trying again."
      );
    }
    setSendingAuthInfo(false);
  };

  const passwordConfirmationErrorText = () => {
    if (confirmationPassword === "") {
      return "";
    } else if (confirmationPassword !== password) {
      return "confirmation password must be the same as password";
    }
  };

  const passwordErrorText = () => {
    if (password === "") {
      return "";
    } else if (password.length < 6) {
      return "password must be at least 6 characters long";
    }
  };

  const loginForm = () => (
    <>
      <Typography variant="h4" gutterBottom align="center">
        User Login
      </Typography>
      <img
        style={{ height: "150px" }}
        src={"/assets/crtLogo.svg"}
        alt="CRT Logo"
      />
      <TextField
        id="login-email"
        label="email"
        type="email"
        value={userName}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setUserName(e.target.value)}
      />
      <TextField
        id="login-email"
        label="password"
        type="password"
        InputLabelProps={{ shrink: true }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {loginError && (
        <Typography
          variant="body1"
          gutterBottom
          align="center"
          style={{ color: "red" }}
        >
          {loginError}
        </Typography>
      )}
      <div className={"flexColumnCC login-button-box"}>
        {/* <Turnstile
          siteKey="0x4AAAAAAAXh3zaxWOXHSD9E"
          onSuccess={(token) => {
            setCaptchaToken(token);
          }}
        /> */}

        <Button className="login-button" variant="contained" onClick={onLogin}>
          {sendingAuthInfo ? "Logging In" : "Login"}
        </Button>
        <Button
          className="login-button"
          variant="outlined"
          onClick={() => setLoginPageDisplay(LoginPages.SIGN_UP_PAGE)}
        >
          Sign Up
        </Button>
        <Button
          className="login-button"
          variant="text"
          onClick={() => setLoginPageDisplay(LoginPages.RECOVER_PASSWORD)}
          style={{ width: "180px" }}
        >
          Forgot Password?
        </Button>
      </div>
    </>
  );

  const TermsOfServiceModal = () => (
    <Modal
      open={openTOSModal}
      onClose={() => setOpenTOSModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/*
      // @ts-ignore */}
      <div style={style}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Terms Of Service</Typography>
          <IconButton
            onClick={() => {
              setOpenTOSModal(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <div
          style={{
            overflow: "scroll",
            height: "90%",
          }}
        >
          <Typography id="modal-modal-description">
            <TOSText />
          </Typography>
        </div>
      </div>
    </Modal>
  );

  const signUpForm = () => (
    <>
      <TermsOfServiceModal />
      <Typography variant="h4" gutterBottom align="center">
        Create A New Account
      </Typography>
      <img
        style={{ height: "150px" }}
        src={"/assets/crtLogo.svg"}
        alt="CRT Logo"
      />
      <TextField
        id="login-email"
        label="email"
        value={userName}
        type={"email"}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setUserName(e.target.value)}
      />
      <TextField
        id="login-password"
        label="password"
        type="password"
        helperText={passwordErrorText()}
        error={password !== "" && password.length < 6}
        InputLabelProps={{ shrink: true }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        id="confirm-password"
        label="confirm-password"
        type="password"
        InputLabelProps={{ shrink: true }}
        value={confirmationPassword}
        error={confirmationPassword !== password && confirmationPassword !== ""}
        helperText={passwordConfirmationErrorText()}
        onChange={(e) => setConfirmationPassword(e.target.value)}
      />
      {signUpStatusText && (
        <Typography variant="body1" gutterBottom align="center">
          {signUpStatusText}
        </Typography>
      )}
      <div className={"flexColumnCC login-button-box"}>
        <div className="tos-checkbox-container">
          <Checkbox
            value={termsOfServiceCheckbox}
            onChange={() => setTermsOfServiceCheckbox(!termsOfServiceCheckbox)}
          />
          <p>
            I agree to the CRT{" "}
            <Link
              onClick={() => {
                setOpenTOSModal(true);
              }}
              style={{ cursor: "pointer" }}
            >
              terms of service
            </Link>
          </p>
        </div>
        <Button
          className="sign-up-button"
          variant="contained"
          onClick={onSignUp}
          disabled={
            password.length < 6 ||
            confirmationPassword !== password ||
            disableLoginAction ||
            !termsOfServiceCheckbox
          }
        >
          {sendingAuthInfo ? "Creating Account" : "Create Account"}
        </Button>
        <Button
          className="sign-up-button"
          variant="outlined"
          onClick={() => {
            setLoginPageDisplay(LoginPages.LOGIN_PAGE);
            setLoginError(null);
          }}
        >
          Back To Login
        </Button>
      </div>
    </>
  );

  const forgotPasswordForm = () => (
    <>
      <Typography variant="h4" gutterBottom align="center">
        Recover Password
      </Typography>
      <Typography variant="body1" gutterBottom align="center">
        Please enter your account email to get a verification link sent to reset
        your password. Please allow a few minutes for the email to get delivered
        before trying again.
      </Typography>
      <TextField
        id="account-recovery"
        label="account-recovery"
        type="text"
        placeholder="account email"
        InputLabelProps={{ shrink: true }}
        value={resetPasswordEmail}
        error={resetErrorChecking(resetPasswordEmail)}
        helperText={
          resetErrorChecking(resetPasswordEmail)
            ? "please enter a valid email"
            : ""
        }
        onChange={(e) => setResetPasswordEmail(e.target.value)}
      />
      <div className={"flexColumnCC login-button-box"}>
        <Button
          className="sign-up-button"
          variant="contained"
          onClick={() => {
            onResetPassword();
          }}
          disabled={
            !(
              resetPasswordEmail.length > 1 && resetPasswordEmail.includes("@")
            ) || disableLoginAction
          }
        >
          {sendingAuthInfo ? "Sending Link" : "Send Link"}
        </Button>
        <Button
          className="sign-up-button"
          variant="outlined"
          onClick={() => {
            setLoginPageDisplay(LoginPages.LOGIN_PAGE);
            setLoginError(null);
          }}
        >
          Back To Login
        </Button>
      </div>
    </>
  );

  return (
    <div
      className={"login-background-container"}
      style={{
        boxShadow: theme.shadows[5],
      }}
    >
      {loginPageDisplay === LoginPages.SIGN_UP_PAGE && <>{signUpForm()}</>}
      {loginPageDisplay === LoginPages.LOGIN_PAGE && <>{loginForm()}</>}
      {loginPageDisplay === LoginPages.RECOVER_PASSWORD && (
        <>{forgotPasswordForm()}</>
      )}
    </div>
  );
};
