export const photosObjectDBUpdate = async (
  supabase: any, // the supabase client
  photoObject: any, // the photos object
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if the photos object has changed
  if (!(JSON.stringify(profile?.user_photos) === JSON.stringify(photoObject))) {
    const { error } = await supabase
      .from("profiles")
      .update({ user_photos: photoObject })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};
