import { useState } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import {
  businessHoursDBUpdate,
  BusinessHours,
  BusinessHoursForm,
} from "../SharedComponents/BusinessHours";
import { ScreenPreviewAndFormContainer } from "./components/PageDisplayHelpers";
export const BusinessHoursPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
  displayPreviewImage,
}: any) => {
  const [businessHours, setBusinessHours] = useState<BusinessHours>(
    JSON.parse(JSON.stringify(profile.business_hours)) as BusinessHours // || defaultBusinessHours
  );

  const supabase = supabaseClient();

  const onSubmit = () => {
    businessHoursDBUpdate(
      supabase,
      businessHours,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  return (
    <ScreenPreviewAndFormContainer
      displayPreviewImage={displayPreviewImage}
      pageSelect={pageSelect}
      setPageSelect={setPageSelect}
      onSubmit={onSubmit}
      imageSrc={"/assets/addressHoursContact.png"}
    >
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Let people know when you're around.
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Enter your daily business hours.
      </Typography>

      <BusinessHoursForm
        businessHours={businessHours}
        setBusinessHours={setBusinessHours}
      />
    </ScreenPreviewAndFormContainer>
  );
};
