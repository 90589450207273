import { useState, useEffect } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { MoreInfoOnClick } from "./components/PageDisplayHelpers";
import { LinearProgressBar } from "./components/pageUpdateHelpers";

type DomainInformationType = {
  wantsNewDomain: boolean;
  hasWebsite: boolean;
  newDomainChoiceOne: string;
  newDomainChoiceTwo: string;
  newDomainChoiceThree: string;
  wantsHelpWithTransferInfo: boolean;
  currentWebUrl: string;
  authCode: string;
  freeDomainChoice: string;
};

const singUpDomainInformationDefault = {
  wantsNewDomain: false,
  hasWebsite: false,
  newDomainChoiceOne: "",
  newDomainChoiceTwo: "",
  newDomainChoiceThree: "",
  wantsHelpWithTransferInfo: false,
  currentWebUrl: "",
  authCode: "",
  freeDomainChoice: "",
};

export const DomainInformationPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
}: any) => {
  const [domainQuestionDisplay, setDomainQuestionDisplay] =
    useState<string>("startingQuestion");

  const [domainQuestionHistory, setDomainQuestionHistory] = useState<
    Array<string>
  >([]);

  const [domainNameDBQuestions, setDomainNameDBQuestions] =
    useState<DomainInformationType>(singUpDomainInformationDefault);

  const supabase = supabaseClient();

  const advanceStep = (nextStep: string) => {
    setDomainQuestionHistory([...domainQuestionHistory, domainQuestionDisplay]);
    setDomainQuestionDisplay(nextStep);
  };

  const onSubmit = async () => {
    // only submit if domain info changed
    if (
      !(
        JSON.stringify(profile?.sign_up_flow_domain_information) ===
        JSON.stringify(domainNameDBQuestions)
      )
    ) {
      const { error } = await supabase
        .from("profiles")
        .update({
          sign_up_flow_domain_information: domainNameDBQuestions,
          completed_first_time_login_flow: true,
        })
        .eq("id", profile.id);
      setUpdateUserProfile(updateUserProfile + 1);
    }
  };

  useEffect(() => {
    console.log(domainNameDBQuestions);
  }, [domainNameDBQuestions]);

  const ProceedToPaymentsPage = () => (
    <Button
      variant="contained"
      sx={{
        marginTop: "20px",
        maxWidth: "120px",
      }}
      onClick={() => {
        setPageSelect(pageSelect + 1);
        onSubmit();
      }}
    >
      next page
    </Button>
  );

  const goBackAStep = () => {
    const domainQuestionHistoryCopy = [...domainQuestionHistory];
    const previousStep = domainQuestionHistoryCopy.pop();
    setDomainQuestionDisplay(previousStep || "startingQuestion");
    setDomainQuestionHistory(domainQuestionHistoryCopy);
  };

  const BackUpButton = () => (
    <Button
      variant="outlined"
      sx={{
        marginTop: "30px",
        maxWidth: "140px",
      }}
      onClick={() => {
        goBackAStep();
      }}
    >
      go back a question
    </Button>
  );

  const StartingQuestion = () => (
    <>
      <Typography variant="body1" gutterBottom align="left">
        <p>Do you currently have a website?</p>
      </Typography>
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          onClick={() => {
            advanceStep("wouldYouLikeNewURL");
            setDomainNameDBQuestions({
              ...domainNameDBQuestions,
              hasWebsite: true,
            });
          }}
        >
          yes
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            advanceStep("enterNewDomainChoices");
            setDomainNameDBQuestions({
              ...domainNameDBQuestions,
              hasWebsite: false,
              wantsNewDomain: true,
            });
          }}
        >
          no
        </Button>
      </div>
    </>
  );

  const WouldYouLikeNewURLQuestion = () => (
    <>
      <Typography variant="body1" gutterBottom align="left">
        <p>
          Would you like a new website URL? ex:{" "}
          <strong>www.your_new_url.com</strong>
          <MoreInfoOnClick
            inputText={`A website URL is where people go to visit your website, like facebook.com or google.com. If you like your current URL, please select 'no'. If you are interested in a new one, please select 'yes'.`}
          />{" "}
        </p>
      </Typography>
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          onClick={() => {
            advanceStep("enterNewDomainChoices");
            setDomainNameDBQuestions({
              ...domainNameDBQuestions,
              wantsNewDomain: true,
            });
          }}
        >
          yes
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            advanceStep("helpWithTransferQuestion");
            setDomainNameDBQuestions({
              ...domainNameDBQuestions,
              wantsNewDomain: false,
            });
          }}
        >
          no
        </Button>
      </div>
      <BackUpButton />
    </>
  );

  const enterNewDomainChoicesQuestion = () => (
    <>
      <Typography variant="body1" gutterBottom align="left">
        Give us a few different domain names (example: www.domainName.com) that
        sound good to you, sometimes a domain can be taken but we will try to
        get you your first choice!{" "}
        <MoreInfoOnClick
          inputText={`Domains can range from $10 to thousands of dollars or may already be in use. CRT Digital will to try to get your first choice while also ensuring there are no surprise bills!`}
        />
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        align="left"
        style={{ marginTop: "20px", marginBottom: "10px" }}
      >
        Fisrt Choice
      </Typography>
      <TextField
        fullWidth
        id="domain-one"
        placeholder="ex: company-name"
        value={domainNameDBQuestions.newDomainChoiceOne}
        onChange={(e) =>
          setDomainNameDBQuestions({
            ...domainNameDBQuestions,
            newDomainChoiceOne: e.target.value,
          })
        }
      />
      <Typography
        variant="body1"
        gutterBottom
        align="left"
        style={{ marginTop: "20px", marginBottom: "10px" }}
      >
        Second Choice
      </Typography>
      <TextField
        fullWidth
        id="domain-two"
        placeholder="ex: yourNameCompanyName"
        type="text"
        value={domainNameDBQuestions.newDomainChoiceTwo}
        onChange={(e) =>
          setDomainNameDBQuestions({
            ...domainNameDBQuestions,
            newDomainChoiceTwo: e.target.value,
          })
        }
      />
      <Typography
        variant="body1"
        gutterBottom
        align="left"
        style={{ marginTop: "20px", marginBottom: "10px" }}
      >
        Third Choice
      </Typography>
      <TextField
        fullWidth
        id="domain-three"
        placeholder="ex: myfancywebsite"
        value={domainNameDBQuestions.newDomainChoiceThree}
        onChange={(e) =>
          setDomainNameDBQuestions({
            ...domainNameDBQuestions,
            newDomainChoiceThree: e.target.value,
          })
        }
      />
      <ProceedToPaymentsPage />
      <BackUpButton />
    </>
  );

  const HelpWithTransferQuestion = () => (
    <>
      <Typography
        variant="body1"
        gutterBottom
        align="left"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        Would you like CRT Digital to contact you to help begin the transfer
        process?{" "}
        <MoreInfoOnClick
          inputText={`The transfer process requires obtaining an authorization code from your current website provider and using it to display your new website. CRT is happy to help with this process!`}
        />
      </Typography>

      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          onClick={() => {
            advanceStep("doYouKnowYourURL");
            setDomainNameDBQuestions({
              ...domainNameDBQuestions,
              wantsHelpWithTransferInfo: true,
            });
          }}
        >
          yes
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            advanceStep("noHelpWithTransferQuestion");
            setDomainNameDBQuestions({
              ...domainNameDBQuestions,
              wantsHelpWithTransferInfo: false,
            });
          }}
        >
          no
        </Button>
      </div>
      <BackUpButton />
    </>
  );

  const noHelpWithTransferQuestion = () => (
    <>
      <Typography
        variant="body1"
        gutterBottom
        align="left"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        Please enter your current url and auth code for transfer
        <MoreInfoOnClick
          inputText={`Go back a step if you aren't sure what this means and select yes to having CRT Digital help you to complete this step.`}
        />
      </Typography>
      <p>Current website URL</p>
      <TextField
        type="text"
        fullWidth
        id="www.yourcurrentwebsite.com"
        label="www.yourcurrentwebsite.com"
        value={domainNameDBQuestions.currentWebUrl}
        onChange={(e) =>
          setDomainNameDBQuestions({
            ...domainNameDBQuestions,
            currentWebUrl: e.target.value,
          })
        }
      />
      <Typography
        variant="body1"
        gutterBottom
        align="left"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        Domain Autherization Code{" "}
        <MoreInfoOnClick
          inputText={
            "Authorization Code is a unique alphanumeric code assigned to a domain name by the current registrar. This code is required when transferring a domain name from one registrar to another."
          }
        />
      </Typography>
      <TextField
        type="text"
        fullWidth
        id="Autherization Code"
        label="Autherization Code"
        value={domainNameDBQuestions.authCode}
        onChange={(e) =>
          setDomainNameDBQuestions({
            ...domainNameDBQuestions,
            authCode: e.target.value,
          })
        }
      />
      <ProceedToPaymentsPage />
      <BackUpButton />
    </>
  );

  const doYouKnowYourCurrentURLQuestion = () => (
    <>
      <Typography
        variant="body1"
        gutterBottom
        align="left"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        If you know your current url (website) please put it here (optional)
      </Typography>
      <TextField
        type="text"
        fullWidth
        id="yourwebsiteurl"
        label="www.yourwebsiteurl.com"
        value={domainNameDBQuestions.currentWebUrl}
        onChange={(e) =>
          setDomainNameDBQuestions({
            ...domainNameDBQuestions,
            currentWebUrl: e.target.value,
          })
        }
      />
      <ProceedToPaymentsPage />
      <BackUpButton />
    </>
  );

  const websiteQuestionSelect = () => {
    switch (domainQuestionDisplay) {
      case "startingQuestion":
        return <StartingQuestion />;
      case "wouldYouLikeNewURL":
        return <WouldYouLikeNewURLQuestion />;
      case "enterNewDomainChoices":
        return <>{enterNewDomainChoicesQuestion()}</>;
      case "helpWithTransferQuestion":
        return <HelpWithTransferQuestion />;
      case "doYouKnowYourURL":
        return <>{doYouKnowYourCurrentURLQuestion()}</>;
      case "noHelpWithTransferQuestion":
        return <>{noHelpWithTransferQuestion()}</>;
      default:
        return <>something went wrong please refresh the page</>;
    }
  };
  return (
    <div className="flexColumn" style={{ width: "100%", maxWidth: "600px" }}>
      <LinearProgressBar pageSelect={pageSelect} />
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Almost there!
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Lets figure out where to host this website.
      </Typography>
      {websiteQuestionSelect()}
    </div>
  );
};
