import { NextButton } from "./components/pageUpdateHelpers";
import { useState } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import { ScreenPreviewAndFormContainer } from "./components/PageDisplayHelpers";
import {
  businessAddressesDBUpdate,
  DefaultBusinessAddressObject,
  BusinessAddress,
  BusinessAddressForm,
} from "../SharedComponents/BusinessAddresses";

export const BusinessAddressesPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
  displayPreviewImage,
}: any) => {
  const [businessAddresses, setBusinessAddresses] = useState<
    Array<BusinessAddress>
  >(
    profile?.business_addresses?.length > 0
      ? profile?.business_addresses
      : [{ ...DefaultBusinessAddressObject }]
  );
  const supabase = supabaseClient();

  //   useEffect(() => {
  //     console.log(businessAddresses);
  //   }, [businessAddresses]);

  const onSubmit = () => {
    businessAddressesDBUpdate(
      supabase,
      businessAddresses,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  return (
    <ScreenPreviewAndFormContainer
      displayPreviewImage={displayPreviewImage}
      pageSelect={pageSelect}
      setPageSelect={setPageSelect}
      onSubmit={onSubmit}
      imageSrc={"/assets/addressHoursContact.png"}
    >
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Add your location details.
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Add up to 3 business addresses you want on your site.
      </Typography>
      <BusinessAddressForm
        businessAddresses={businessAddresses}
        setBusinessAddresses={setBusinessAddresses}
      />
    </ScreenPreviewAndFormContainer>
  );
};
