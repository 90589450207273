import "./FirstTimeLoginFlow.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export const FirstTimeFlowSplash = ({ setPageSelect, pageSelect }: any) => {
  const NextButton = () => (
    <Button
      variant="contained"
      style={{ width: "200px" }}
      onClick={() => {
        setPageSelect(pageSelect + 1);
      }}
    >
      Click here to start
    </Button>
  );

  return (
    <div className={"flexColumnCC first-splash-container"}>
      <Typography variant="body1" gutterBottom align="center">
        This form is just to collect the necessary details to get your website
        up and running, you will be able to modify and add any fields you see in
        this form. So feel free to leave any optional sections blank to come
        back and modify them later!
      </Typography>
      <NextButton />
    </div>
  );
};
