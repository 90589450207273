import { NextButton } from "./components/pageUpdateHelpers";
import { useState } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import { ScreenPreviewAndFormContainer } from "./components/PageDisplayHelpers";
import {
  BusinessServicesDBUpdate,
  BusinessServices,
  BusinessServicesForm,
  DefaultBusinessServicesObject,
} from "../SharedComponents/BusinessServices";

export const BusinessServicesPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
  displayPreviewImage,
}: any) => {
  const [businessServices, setBusinessServices] = useState<
    Array<BusinessServices>
  >(
    profile?.business_services?.length > 0
      ? profile?.business_services
      : [{ ...DefaultBusinessServicesObject }]
  );
  const supabase = supabaseClient();

  const onSubmit = () => {
    BusinessServicesDBUpdate(
      supabase,
      businessServices,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  return (
    <ScreenPreviewAndFormContainer
      displayPreviewImage={displayPreviewImage}
      pageSelect={pageSelect}
      setPageSelect={setPageSelect}
      onSubmit={onSubmit}
      imageSrc={"/assets/serviceExample.png"}
    >
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Let people know what services you offer.
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Make sure to go into detail!
      </Typography>

      <BusinessServicesForm
        businessServices={businessServices}
        setBusinessServices={setBusinessServices}
        firstTimeAssistanceText={
          !profile.completed_first_time_login_flow &&
          profile.user_wants_website_review
        }
      />
    </ScreenPreviewAndFormContainer>
  );
};
