import supabaseClient from "../supabaseClient/supabaseClient";
import { Logout } from "../auth/Logout";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { AdminTable } from "./Components/AdminTable";
import { DashboardPage } from "../Dashboard/DashboardPage";
import dayjs from "dayjs";
import { DashboardPageWrapper } from "../App";

export const AdminPortal = () => {
  const [userData, setUserData] = useState<any>([]);
  const supabase = supabaseClient();

  const [adminBackDoorProfile, setAdminBackDoorProfile] = useState(null);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    console.log(userData);
  }, [userData]);
  const getUserData = async () => {
    const { data } = await supabase.from("profiles").select();
    const result = JSON.parse(JSON.stringify(data)).sort((a: any, b: any) => {
      return dayjs(b.updated_at).isAfter(dayjs(a.updated_at)) ? 1 : -1;
    });
    setUserData(data ? result : []);
  };

  return (
    <DashboardPageWrapper>
      <div className="logout-button">
        <Logout />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <AdminTable
            userData={userData}
            setAdminBackDoorProfile={setAdminBackDoorProfile}
          />
        </div>
        {adminBackDoorProfile && (
          <div>
            <DashboardPage profile={adminBackDoorProfile} />
          </div>
        )}
      </div>
    </DashboardPageWrapper>
  );
};
