import { Typography, TextField, Button } from "@mui/material";
import { DashboardPageWrapper } from "../App";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import { useNavigate } from "react-router-dom";

export const PasswordRecovery = () => {
  const theme = useTheme();
  const supabase = supabaseClient();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [newPasswordStatusText, setNewPasswordStatusText] = useState<
    string | null
  >();
  const [resettingPassword, setResettingPassword] = useState<boolean>(false);
  const [disableLoginAction, setDisableLoginAction] = useState<boolean>(false);

  const passwordConfirmationErrorText = () => {
    if (confirmNewPassword === "") {
      return "";
    } else if (confirmNewPassword !== newPassword) {
      return "confirmation password must be the same as password";
    }
  };

  const passwordErrorText = () => {
    if (newPassword === "") {
      return "";
    } else if (newPassword.length < 6) {
      return "password must be at least 6 characters long";
    }
  };

  const onPasswordRecoveryClick = async () => {
    setResettingPassword(true);
    setNewPasswordStatusText(null);
    // Disable the button
    setDisableLoginAction(true);

    // Enable the button after 5 seconds
    setTimeout(() => {
      setDisableLoginAction(false);
    }, 5000);

    const { data, error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      setNewPasswordStatusText(error?.message);
    } else {
      setNewPasswordStatusText("Password Updated, taking you to your account");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    setResettingPassword(false);
  };

  return (
    <DashboardPageWrapper>
      <div
        className={"login-background-container"}
        style={{
          boxShadow: theme.shadows[5],
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Create New Password
        </Typography>
        <img
          style={{ height: "150px" }}
          src={"/assets/crtLogo.svg"}
          alt="CRT Logo"
        />
        <TextField
          id="login-password"
          label="password"
          type="password"
          helperText={passwordErrorText()}
          error={newPassword !== "" && newPassword.length < 6}
          InputLabelProps={{ shrink: true }}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          id="confirm-password"
          label="confirm-password"
          type="password"
          InputLabelProps={{ shrink: true }}
          value={confirmNewPassword}
          error={
            confirmNewPassword !== newPassword && confirmNewPassword !== ""
          }
          helperText={passwordConfirmationErrorText()}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        {newPasswordStatusText && (
          <Typography variant="body1" gutterBottom align="center">
            {newPasswordStatusText}
          </Typography>
        )}
        <div className={"flexColumnCC login-button-box"}>
          <Button
            className="sign-up-button"
            variant="contained"
            onClick={onPasswordRecoveryClick}
            disabled={
              newPassword.length < 6 ||
              confirmNewPassword !== newPassword ||
              disableLoginAction
            }
          >
            {resettingPassword ? "Updating Password" : "Update Password"}
          </Button>
          <Button
            className="sign-up-button"
            variant="outlined"
            onClick={() => {
              navigate("/");
            }}
          >
            Back To Login
          </Button>
        </div>
      </div>
    </DashboardPageWrapper>
  );
};
