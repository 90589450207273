import { useState, useEffect } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "react-responsive";

export type BusinessHours = {
  monday: {
    openTime: string;
    closeTime: string;
    isClosed: boolean;
  };
  tuesday: {
    openTime: string;
    closeTime: string;
    isClosed: boolean;
  };
  wednesday: {
    openTime: string;
    closeTime: string;
    isClosed: boolean;
  };
  thursday: {
    openTime: string;
    closeTime: string;
    isClosed: boolean;
  };
  friday: {
    openTime: string;
    closeTime: string;
    isClosed: boolean;
  };
  saturday: {
    openTime: string;
    closeTime: string;
    isClosed: boolean;
  };
  sunday: {
    openTime: string;
    closeTime: string;
    isClosed: boolean;
  };
  hoursTextDescription: string;
  showBusinessHours: boolean;
  showHoursDescription: boolean;
};

export const dayKeys = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const defaultBusinessHours = {
  monday: {
    openTime: "",
    closeTime: "",
    isClosed: false,
  },
  tuesday: {
    openTime: "",
    closeTime: "",
    isClosed: false,
  },
  wednesday: {
    openTime: "",
    closeTime: "",
    isClosed: false,
  },
  thursday: {
    openTime: "",
    closeTime: "",
    isClosed: false,
  },
  friday: {
    openTime: "",
    closeTime: "",
    isClosed: false,
  },
  saturday: {
    openTime: "",
    closeTime: "",
    isClosed: false,
  },
  sunday: {
    openTime: "",
    closeTime: "",
    isClosed: false,
  },
  hoursTextDescription: "",
  showBusinessHours: true,
  showHoursDescription: false,
};

export const businessHoursDBUpdate = async (
  supabase: any, // the supabase client
  businessHours: any, //the business hours object
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if hours have changed
  if (
    !(JSON.stringify(profile?.business_hours) === JSON.stringify(businessHours))
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ business_hours: businessHours })
      .eq("id", profile.id);
    console.log(error);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const BusinessHoursForm = ({
  businessHours,
  setBusinessHours,
  mobileDisplayQuery = "(max-width: 500px)",
}: {
  businessHours: any;
  setBusinessHours: any;
  mobileDisplayQuery?: string;
}) => {
  const mobileTimeDivider = useMediaQuery({
    query: mobileDisplayQuery,
  });

  const businessHoursDay = (day: string) => {
    return (
      <div
        className={"business-hours-form-container"}
        style={{
          flexDirection: mobileTimeDivider ? "column" : "row",
          alignItems: mobileTimeDivider ? "flex-start" : "flex-end",
        }}
      >
        <div
          className={"open-hours-container"}
          style={{
            paddingBottom: mobileTimeDivider ? "20px" : "0px",
          }}
        >
          <div>
            <Typography
              className={"first-time-login-page-input-title"}
              gutterBottom
              align="left"
            >
              <strong>{day.charAt(0).toUpperCase() + day.slice(1)}</strong>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                sx={{ minWidth: "140px" }}
                slotProps={{
                  textField: {
                    // errors disabled for now
                    error: false,
                  },
                }}
                disabled={
                  // @ts-ignore
                  businessHours[day].isClosed
                }
                value={
                  // @ts-ignore
                  dayjs(businessHours[day].openTime, "hh:mm A")
                }
                onChange={(e) => {
                  const businessHoursCopy = businessHours;
                  // @ts-ignore
                  businessHoursCopy[day].openTime = (
                    e as unknown as Dayjs
                  ).format("hh:mm A");

                  setBusinessHours({ ...businessHoursCopy });
                }}
                //   defaultValue={dayjs("2022-04-17T15:30")}
              />
            </LocalizationProvider>
          </div>
          <div style={{ padding: "10px" }}>
            <Typography align={"center"}>To</Typography>
          </div>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            sx={{ minWidth: "140px" }}
            value={
              // @ts-ignore
              dayjs(businessHours[day].closeTime, "hh:mm A")
            }
            slotProps={{
              textField: {
                // errors disabled for now
                error: false,
              },
            }}
            disabled={
              // @ts-ignore
              businessHours[day].isClosed
            }
            onChange={(e) => {
              const businessHoursCopy = { ...businessHours };
              // @ts-ignore
              businessHoursCopy[day].closeTime = (e as unknown as Dayjs).format(
                "hh:mm A"
              );

              setBusinessHours({ ...businessHoursCopy });
            }}
            //   defaultValue={dayjs("2022-04-17T15:30")}
          />
        </LocalizationProvider>
        <div
          style={{ display: "flex", alignItems: "center", padding: "0px 10px" }}
        >
          <Typography>Closed</Typography>
          <Checkbox
            checked={
              // @ts-ignore
              businessHours[day].isClosed
            }
            onChange={() => {
              const businessHoursCopy = { ...businessHours };
              // @ts-ignore
              businessHoursCopy[day].isClosed =
                // @ts-ignore
                !businessHoursCopy[day].isClosed;

              setBusinessHours({ ...businessHoursCopy });
            }}
          />
        </div>
        <div style={{ width: "100%" }}>{mobileTimeDivider && <Divider />}</div>
      </div>
    );
  };

  return (
    <>
      <div className={"daily-hours-input-container"}>
        {dayKeys.map((key: string, index: number) => (
          <div key={index}>{businessHoursDay(key)}</div>
        ))}
      </div>

      <div style={{ marginTop: "20px" }}>
        <Typography
          className={"first-time-login-page-input-title"}
          variant="body1"
          gutterBottom
          align="left"
        >
          Business hours description (optional)
        </Typography>
        <TextField
          type="text"
          fullWidth
          id="busienss-name"
          placeholder="ex: Closed at noon for
          lunch, call after hours etc"
          multiline
          rows={2}
          value={businessHours.hoursTextDescription}
          onChange={(e) =>
            setBusinessHours({
              ...businessHours,
              hoursTextDescription: e.target.value,
            })
          }
        />
      </div>
      <div style={{ marginTop: "20px", marginBottom: "40px" }}>
        <Grid
          container
          spacing={2}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Grid item xs={6}>
            <Typography variant="body1" gutterBottom align="left">
              Show Business Hours
            </Typography>
            <Checkbox
              checked={businessHours.showBusinessHours}
              onChange={(e) =>
                setBusinessHours({
                  ...businessHours,
                  showBusinessHours: !businessHours.showBusinessHours,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" gutterBottom align="left">
              Show Business Hours Description
            </Typography>
            <Checkbox
              checked={
                businessHours.showHoursDescription &&
                businessHours.hoursTextDescription.length > 1
              }
              onChange={(e) =>
                setBusinessHours({
                  ...businessHours,
                  showHoursDescription:
                    !businessHours.showHoursDescription &&
                    businessHours.hoursTextDescription.length > 1,
                })
              }
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
