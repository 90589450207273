export const TOSText = () => (
  <>
    <p>
      This Website Hosting Terms of Service ("Agreement") is entered into by and
      between CRT Digital ("Provider") and the user ("Client") accessing hosting
      services provided by the Provider. This Agreement governs Client's use of
      Provider's website hosting and creation services.
    </p>
    <br />
    <strong>1. Acceptance of Terms</strong>
    <br />
    <br />
    By using Provider's website hosting services, Client agrees to be bound by
    the terms and conditions set forth in this Agreement. If Client does not
    agree to these terms, Client must refrain from using the services provided
    by the Provider.
    <br />
    <br />
    <strong>2. Service Description</strong>
    <br />
    <br />
    Provider agrees to provide Client with website building, updating, and
    hosting services. Client does not own or have any rights to keep any website
    code, or CRT software after termination of contract.
    <br />
    <br />
    <strong>3. Payment</strong>
    <br />
    <br />
    Client agrees to pay Provider the fees specified in the selected hosting
    plan. Provider reserves the right to suspend or terminate services for
    non-payment.
    <br />
    <br />
    <strong>4. Term and Termination</strong>
    <br />
    <br />
    This Agreement shall remain in effect until terminated by either party.
    Either party may terminate this Agreement upon written notice to the other
    party. Upon termination, the Provider may delete all data, files, or other
    information stored on its servers. As well as release the clients domain.
    <br />
    <br />
    <strong>5. Client Responsibilities</strong>
    <br />
    <br />
    Client agrees to:
    <ul>
      <li>Comply with all applicable laws and regulations.</li>
      <li>Maintain the security of Client's account credentials.</li>
      <li>
        Use resources responsibly and not engage in activities that may degrade
        the performance of Provider's servers.
      </li>
      <li>
        Ensure that all content uploaded to Provider's servers does not violate
        any laws or infringe upon the rights of any third party.
      </li>
    </ul>
    <p>
      Client is responsible for providing information for domain transfer, due
      to the nature of domain transfers it may take several weeks for any domain
      transfers to go into full effect. Client is responsible for figuring out
      this timeline and adjusting billing accordingly. CRT will also help with
      this process Client is responsible for continued ownership of domain and
      domain transfer after termination of service to new hosting provider if
      they choose to do so.
    </p>
    <br />
    <br />
    <strong>6. Provider Responsibilities</strong>
    <br />
    <br />
    Provider agrees to:
    <ul>
      <li>Provide reasonable technical support to Client.</li>
      <li>
        Maintain the security and integrity of its rented servers. CRT uses
        ShockHosting and is not responsible for any downtime due to its server
        provider CRT will take measures to ensure server uptime as much as
        possible, and will refund any extended server downtimes caused by CRT.
      </li>
      <li>
        Take reasonable measures to ensure the availability of its services.
      </li>
    </ul>
    <br />
    <br />
    <strong>7. Limitation of Liability</strong>
    <br />
    <br />
    Provider shall not be liable for any damages arising out of or in connection
    with the use of its services, including but not limited to, indirect,
    incidental, consequential, or punitive damages.
    <br />
    <br />
    <strong>8. Indemnification</strong>
    <br />
    <br />
    Client agrees to indemnify and hold Provider harmless from any claims,
    damages, or liabilities arising out of Client's use of Provider's services.
    <br />
    <br />
    <strong>9. Modifications to Terms</strong>
    <br />
    <br />
    Provider reserves the right to modify this Agreement at any time. Any
    modifications will be effective immediately upon posting on Provider's
    website. Client's continued use of Provider's services after such
    modifications constitutes acceptance of the modified terms.
    <br />
    <br />
    <strong>10. Governing Law</strong>
    <br />
    <br />
    This Agreement shall be governed by and construed in accordance with the
    laws of Texas. Any disputes arising out of or in connection with this
    Agreement shall be subject to the exclusive jurisdiction of the courts of
    Texas.
    <br />
    <br />
    <strong>11. Entire Agreement</strong>
    <br />
    <br />
    This Agreement constitutes the entire agreement between Provider and Client
    with respect to the subject matter hereof, and supersedes all prior and
    contemporaneous agreements and understandings, whether written or oral.
    <br />
    By using Provider's website hosting services, Client acknowledges that they
    have read, understood, and agree to be bound by this Agreement.
  </>
);
