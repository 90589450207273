import supabaseClient from "../../supabaseClient/supabaseClient";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { photosObjectDBUpdate } from "../../SharedComponents/MultiFieldDBUpdateFunctions";
import { UploadImageComponent } from "../../SharedComponents/ImageComponents/UploadImageComponent";
import {
  businessNameDBUpdate,
  businessSloganDBUpdate,
} from "../../SharedComponents/SingleFieldDBUpdateFunctions";
import {
  businessAddressesDBUpdate,
  DefaultBusinessAddressObject,
  BusinessAddress,
  BusinessAddressForm,
} from "../../SharedComponents/BusinessAddresses";
import {
  businessHoursDBUpdate,
  BusinessHours,
  BusinessHoursForm,
} from "../../SharedComponents/BusinessHours";
import { CardDisplay } from "../components/DashboardPageDisplayComponents";
import { useMediaQuery } from "react-responsive";
import { MobileDashboardBottomBar } from "../components/dashboardNavigation";

export const BusinessInfoPage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setOpenPreviewModal,
}: any) => {
  const supabase = supabaseClient();

  const [businessHours, setBusinessHours] = useState<BusinessHours>(
    JSON.parse(JSON.stringify(profile.business_hours)) as BusinessHours // || defaultBusinessHours
  );

  const [businessName, setBusinessName] = useState<string>(
    profile.business_name
  );

  const [businessSlogan, setBusinessSlogan] = useState<string>(
    profile.business_slogan
  );

  const [businessAddresses, setBusinessAddresses] = useState<
    Array<BusinessAddress>
  >(
    profile?.business_addresses?.length > 0
      ? profile?.business_addresses
      : [{ ...DefaultBusinessAddressObject }]
  );

  const mobileDashboardView = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const [websiteUpdating, setWebsiteUpdating] = useState<boolean>(false);

  const onSubmit = async () => {
    setWebsiteUpdating(true);
    await businessAddressesDBUpdate(
      supabase,
      businessAddresses,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await businessHoursDBUpdate(
      supabase,
      businessHours,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await businessNameDBUpdate(
      supabase,
      businessName,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    await businessSloganDBUpdate(
      supabase,
      businessSlogan,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    setWebsiteUpdating(false);
  };

  const businessAddressesComponent = () => (
    <CardDisplay
      imageSrc={"/assets/GetInTouchSection.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <div style={{ minWidth: "320px" }}>
        <Typography
          // className={"first-time-login-page-title"}
          variant="h5"
          gutterBottom
          align="left"
        >
          Update or add Business Addresses
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          Three Address Max.
        </Typography>
        <BusinessAddressForm
          businessAddresses={businessAddresses}
          setBusinessAddresses={setBusinessAddresses}
        />
      </div>
    </CardDisplay>
  );

  const businessHoursComponent = () => (
    <CardDisplay
      imageSrc={"/assets/GetInTouchSection.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <div style={{ minWidth: "320px" }}>
        <Typography
          // className={"first-time-login-page-title"}
          variant="h5"
          gutterBottom
          align="left"
        >
          Update Business Hours
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          Don't forget to use the description box if needed!
        </Typography>
        <BusinessHoursForm
          businessHours={businessHours}
          setBusinessHours={setBusinessHours}
          mobileDisplayQuery={"(max-width: 900px)"}
        />
      </div>
    </CardDisplay>
  );

  const businessNameAndSloganComponent = () => (
    <CardDisplay
      imageSrc={"/assets/justheader.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <div style={{ marginBottom: "30px" }}>
        <Typography
          // className={"first-time-login-page-title"}
          variant="h5"
          gutterBottom
          align="left"
        >
          Update Business Name and Slogan
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          50 character max for slogan
        </Typography>
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          align="left"
        >
          Business Name
        </Typography>
        <TextField
          type="text"
          fullWidth
          id="busienss-name"
          placeholder="Business Name"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
        />
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          align="left"
          style={{ marginTop: "20px" }}
        >
          Business Slogan
        </Typography>
        <TextField
          type="text"
          fullWidth
          id="busienss-name"
          placeholder="Business Slogan"
          value={businessSlogan}
          onChange={(e) => setBusinessSlogan(e.target.value)}
        />
      </div>
    </CardDisplay>
  );

  const businessLogoComponent = () => (
    <CardDisplay
      imageSrc={"/assets/logoExample.png"}
      mobileDashboardView={mobileDashboardView}
    >
      <div>
        <Typography variant="h5" gutterBottom align="left">
          Add or update a company logo
        </Typography>
        <Typography
          className={"first-time-login-page-subheader"}
          gutterBottom
          align="left"
        >
          Don't have a logo or need a logo update? Contact CRT Digital to learn
          more about our logo design and update offers! If after upload/delete
          you don't see the photo change please refresh the page.
        </Typography>
        <UploadImageComponent
          profile={profile}
          size={"100px"}
          originalFileURL={profile.user_photos.companyLogo}
          onDeletePhoto={async () => {
            await photosObjectDBUpdate(
              supabase,
              { ...profile.user_photos, companyLogo: "" },
              profile,
              setUpdateUserProfile,
              updateUserProfile
            );
            setUpdateUserProfile(updateUserProfile + 1);
          }}
          onUpload={async (event: any, url: any) => {
            await photosObjectDBUpdate(
              supabase,
              { ...profile.user_photos, companyLogo: url },
              profile,
              setUpdateUserProfile,
              updateUserProfile
            );
            setUpdateUserProfile(updateUserProfile + 1);
          }}
          supabase={supabase}
          photoFileName={"companyLogo"}
        />
      </div>
    </CardDisplay>
  );

  return (
    <>
      <div className="flexColumn">
        {businessNameAndSloganComponent()}
        {businessLogoComponent()}
        {businessAddressesComponent()}
        {businessHoursComponent()}
      </div>
      <MobileDashboardBottomBar
        onSubmitFunction={onSubmit}
        setOpenPreviewModal={setOpenPreviewModal}
        updateFieldDisabled={businessName === ""}
        websiteUpdating={websiteUpdating}
      />
    </>
  );
};
