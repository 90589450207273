import { useCallback, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import supabaseClient from "../supabaseClient/supabaseClient";
import { Button, Typography } from "@mui/material";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const testPublicKey =
  "pk_test_51NTaQLGVBfJC9595OblHGf7zoWA1qVJ8FjihXHR5JEeG2w2LD4wzGj33lFfbScshM7YFR0HivijVC1vnMkRXbsgF00hIEB9V7c";

const prodPublicKey =
  "pk_live_51NTaQLGVBfJC9595zAQobtDdul0HwyXYGsDBvpgnA67ZrEX2e34EvihyXJE9CvonWXSrXqOs9OiFycT6oaWeolET00cPaMQ2AO";

const stripePromise = loadStripe(prodPublicKey);

const YOUR_DOMAIN = "https://crtdigitaldash.com";

export const PaymentsPage = ({ profile }: any) => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");
  const [testAPI, setTestAPI] = useState<any>(null);
  const supabase = supabaseClient();
  const navigate = useNavigate();

  const updateUserPaymentStatus = async () => {
    const { error } = await supabase
      .from("profiles")
      .update({ initial_payment_made: true })
      .eq("id", profile.id);
  };

  const getCartSession = async (sessionId: string) => {
    // const { data, error } = await supabase.functions.invoke("stripe", {
    //   body: { functionCall: "GET", sessionId: sessionId },
    // });
    const { data, error } = await supabase.functions.invoke("stripe-prod", {
      body: { functionCall: "GET", sessionId: sessionId },
    });

    if (data) {
      setStatus(data.status);
      setCustomerEmail(data.customer_email);
      if (data.status === "complete" && profile) {
        updateUserPaymentStatus();
      }
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      getCartSession(sessionId);
    }
  }, []);

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const fetchClientSecret = useCallback(async () => {
    // const { data, error } = await supabase.functions.invoke("stripe", {
    //   body: {
    //     functionCall: "POST",
    //     checkoutData: {
    //       ui_mode: "embedded",
    //       line_items: [
    //         {
    //           // Provide the exact Price ID (for example, pr_1234) of the product you want to sell
    //           price: "price_1P3LOxGVBfJC9595Tuhj0Jvn",
    //           quantity: 1,
    //         },
    //         { price: "price_1P3LQoGVBfJC9595EXD5yIfn", quantity: 1 },
    //       ],
    //       allow_promotion_codes: true,
    //       mode: "subscription",
    //       return_url: `${YOUR_DOMAIN}/return?session_id={CHECKOUT_SESSION_ID}`,
    //       automatic_tax: { enabled: true },
    //     },
    //   },
    // });
    const { data, error } = await supabase.functions.invoke("stripe-prod", {
      body: {
        functionCall: "POST",
        checkoutData: {
          ui_mode: "embedded",
          line_items: [
            { price: "price_1P6KGaGVBfJC95955AIvqrN0", quantity: 1 },
            { price: "price_1P3JE5GVBfJC9595V1UvxwBx", quantity: 1 },
          ],
          mode: "subscription",
          return_url: `${YOUR_DOMAIN}/return?session_id={CHECKOUT_SESSION_ID}`,
          allow_promotion_codes: true,
          automatic_tax: { enabled: true },
        },
      },
    });
    setTestAPI(data);
  }, []);

  const CheckoutForm = () => {
    return (
      <div id="checkout">
        <EmbeddedCheckoutProvider stripe={stripePromise} options={testAPI}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{ width: "100%", height: "50px", backgroundColor: "white" }}
      ></div>
      {(status === "open" || !status) && (
        <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
          {" "}
          {testAPI && <CheckoutForm />}
        </div>
      )}
      {status === "complete" && (
        <div
          className="flexColumnCC"
          style={{
            backgroundColor: "white",
            padding: " 0px 40px",
            gap: "15px",
            minHeight: "100vh",
          }}
        >
          <Typography textAlign="center" style={{ maxWidth: "500px" }}>
            We appreciate your business! A confirmation email will be sent to{" "}
            {customerEmail}. If you have any questions, please email{" "}
            <a href="mailto:admin@getcrtdigital.com">admin@getcrtdigital.com</a>{" "}
            or call 712-249-3290.
          </Typography>
          <Typography textAlign="center">
            Please click here to go to your new dashboard
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/");
              window.location.reload();
            }}
          >
            Go to dashboard
          </Button>
        </div>
      )}
    </div>
  );
};
