import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { MuiTelInput } from "mui-tel-input";

export const phoneAndEmailDBUpdate = async (
  supabase: any, // the supabase client
  email: any, //the social media links object
  phoneNumber: any,
  profile: any, // current user profile
  setUpdateUserProfile: any, // update the user profile
  updateUserProfile: number // value that updates the profile
) => {
  // only submit if phone or email has changed
  // adding comment to upload
  if (
    (!(phoneNumber === profile.business_phone_number) ||
      !(email === profile.business_email)) &&
    phoneNumber.length >= 2
  ) {
    const { error } = await supabase
      .from("profiles")
      .update({ business_phone_number: phoneNumber, business_email: email })
      .eq("id", profile.id);
    setUpdateUserProfile(updateUserProfile + 1);
  }
};

export const BusinessEmailAndPhoneForm = ({
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
}: {
  phoneNumber: string;
  setPhoneNumber: any;
  email: string;
  setEmail: any;
}) => {
  return (
    <>
      <div className={"phone-number-text-field"}>
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          align="left"
        >
          Phone Number*
        </Typography>
        <MuiTelInput
          defaultCountry={"US"}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e)}
        />
      </div>
      <div className={"contact-email-text-field"}>
        <Typography
          className={"first-time-login-page-input-title"}
          gutterBottom
          align="left"
        >
          Email Address
        </Typography>

        <TextField
          fullWidth
          id="busienss-email"
          placeholder="business@email.com"
          value={email}
          style={{ maxWidth: "300px" }}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
    </>
  );
};
