import supabaseClient from "../supabaseClient/supabaseClient";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

export const Logout = ({
  className = "logout-button",
}: {
  className?: string;
}) => {
  const supabase = supabaseClient();
  const onLogout = async () => {
    const { error } = await supabase.auth.signOut();
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",

    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ padding: "10px 10px 10px 30px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to logout?
          </Typography>
          <div
            style={{
              display: "flex",
              gap: "10px",
              padding: "20px 0px 10px 0px",
            }}
          >
            <Button onClick={onLogout} variant="contained">
              Logout
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Button onClick={handleOpen} variant="outlined" className={className}>
        Logout
      </Button>
    </div>
  );
};

export const LogoutNoModal = ({
  className = "logout-button",
}: {
  className?: string;
}) => {
  const supabase = supabaseClient();
  const onLogout = async () => {
    const { error } = await supabase.auth.signOut();
  };

  return (
    <div>
      <Button onClick={onLogout} variant="outlined" className={className}>
        Logout
      </Button>
    </div>
  );
};
