import { useState, useEffect, useRef } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { Typography } from "@mui/material";
import { Skeleton } from "@mui/material";

export const sendDataToIframe = (profile: any) => {
  const iframe = document.getElementById("inner");
  // we need to know that we have updated the image outside of the previewer inside the previwer
  const imageCacheValues = localStorage.getItem("imageCacheManager");
  let previewerData = profile;
  if (iframe) {
    if (imageCacheValues) {
      previewerData = {
        ...previewerData,
        imageCacheValues: JSON.parse(imageCacheValues),
      };
    }

    //@ts-ignore
    iframe.contentWindow.postMessage(previewerData, "*");
  }
};

export const DashboardPreviewContainer = ({
  profile,
  modalView = false,
}: {
  profile: any;
  modalView?: boolean;
}) => {
  const iframeRef = useRef(null);
  const [iframeScale, setIframeScale] = useState(1);
  const [viewToggle, setViewToggle] = useState<string>("desktop");
  const [mobileIframeShift, setMobileIframeShift] = useState<number>(0);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);

  const handleViewChange = (event: React.SyntheticEvent, newValue: string) => {
    setViewToggle(newValue);
  };

  const handleMessageFromIframe = (event: any) => {
    // Check the origin of the message for security purposes
    if (event.origin !== process.env.REACT_APP_NEXT_IFRAME_URL) return;
    // Check the message data to identify the event
    if (event.data === "iframeLoaded") {
      sendDataToIframe(profile);
      // Perform additional actions once the iframe content has loaded
    }
  };

  useEffect(() => {
    // Add event listener for messages from the iframe
    window.addEventListener("message", handleMessageFromIframe);

    // Cleanup
    return () => {
      window.removeEventListener("message", handleMessageFromIframe);
    };
  }, [profile]);

  const setDimensions = (screenType: string) => {
    if (screenType === "mobile") {
      return { width: "350px", height: "600px" };
    }
    if (screenType === "desktop") {
      return { width: "1300px", height: "800px" };
    }
    return {
      width: "100%",
      height: "100%",
    };
  };

  const setScale = (screenType: string, scale: number) => {
    if (screenType === "mobile") {
      return scale > 350 ? 1 : scale / 350;
    }
    if (screenType === "desktop") {
      return scale / 1300;
    }
    return 1;
  };

  useEffect(() => {
    const resizeIframe = () => {
      //@ts-ignore
      const parentWidth = iframeRef?.current?.parentNode?.offsetWidth;
      const scaleFactor = parentWidth; // / 1300; // Adjust 1000 according to your preference
      if (parentWidth > 350) {
        setMobileIframeShift((parentWidth - 350) / 2);
      }
      setIframeScale(scaleFactor);
    };

    // Initial resize
    resizeIframe();

    // Resize listener
    window.addEventListener("resize", resizeIframe);

    // Cleanup
    return () => {
      window.removeEventListener("resize", resizeIframe);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        border: "1px #e6e6e6",
        borderStyle: "none solid solid solid",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          border: "1px #e6e6e6",
          borderStyle: "none none solid none",
        }}
      >
        {" "}
        <Typography
          gutterBottom
          align="center"
          style={{
            paddingTop: "10px",
            fontSize: "1.5rem",
          }}
        >
          Preview
        </Typography>
      </div>
      <BottomNavigation value={viewToggle} onChange={handleViewChange}>
        <BottomNavigationAction
          label="Desktop"
          value="desktop"
          icon={<PersonalVideoIcon />}
          style={{
            border: "1px #e6e6e6",
            maxWidth: "100%",
          }}
        />
        <BottomNavigationAction
          label="Mobile"
          value="mobile"
          icon={<PhoneIphoneIcon />}
          style={{
            border: "1px #e6e6e6",
            borderStyle: "none solid none solid",
            maxWidth: "100%",
          }}
        />
        <BottomNavigationAction
          label="Fill Preview"
          value="responsive"
          icon={<AspectRatioIcon />}
          style={{
            border: "1px #e6e6e6",
            maxWidth: "100%",
          }}
        />
      </BottomNavigation>
      <div
        style={{
          display: "flex",
          overflow: "scroll",
          height: "-webkit-fill-available",
          border: "1px #e6e6e6",
          borderStyle: "solid none none none",
          position: "relative",
          padding: "0px 5px 0px 5px",
        }}
      >
        {!iframeLoaded && (
          <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
        )}

        <iframe
          id="inner"
          ref={iframeRef}
          height="800"
          width="1300"
          frameBorder="0"
          style={{
            position: "absolute",
            top: "0px",
            left: `${viewToggle === "mobile" ? mobileIframeShift : 0}px`,
            transform: `scale(${setScale(viewToggle, iframeScale)})`,
            transformOrigin: "top left",
            width: setDimensions(viewToggle).width,
            height: setDimensions(viewToggle).height,
            overflow: "scroll",
          }}
          onLoad={() => {
            sendDataToIframe(profile);
            setIframeLoaded(true);
          }}
          src={process.env.REACT_APP_NEXT_IFRAME_URL}
        ></iframe>
      </div>
      <div style={{ height: "56px", width: "100%" }}></div>
    </div>
  );
};
