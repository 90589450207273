import Box from "@mui/material/Box";
import { useState, useEffect, useRef } from "react";
import { Typography, Divider, Modal } from "@mui/material";
import { ServicesPage } from "./ServicesPage/ServicesPage";
import supabaseClient from "../supabaseClient/supabaseClient";
import { BusinessInfoPage } from "./BusinessInfoPage/BusinessInfoPage";
import { BusinessContactInfoPage } from "./BusinessContactInfoPage/BusinessContactInfoPage";
import { AboutInfoPage } from "./AboutInfoPage/AboutInfoPage";
import { GeneralSiteSettingsPage } from "./GeneralSiteSettingsPage/GeneralSiteSettingsPage";
import { PluginsAndToolsPage } from "./PluginsAndToolsPage/PluginsAndToolsPage";
import { Logout } from "../auth/Logout";
import { useMediaQuery } from "react-responsive";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Link from "@mui/material/Link";
import {
  DashboardPreviewContainer,
  sendDataToIframe,
} from "./components/DashboardPreviewContainer";
import {
  TabValues,
  MobileNavigationHamberger,
  dashboardTabs,
  DashboardHelpModal,
  DashboardPreviewModal,
} from "./components/dashboardNavigation";
import {
  userFirstDashboardLoadDBUpdate,
  userWantsLeadGenCallDBUpdate,
} from "../SharedComponents/SingleFieldDBUpdateFunctions";

export const DashboardPage = ({ profile }: { profile: any }) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    TabValues.BUSINESS_INFO
  );

  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);

  const [openLeadGenModal, setOpenLeadGenModal] = useState<boolean>(
    profile.user_first_dashboard_load
  );

  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };
  const [updateUserProfile, setUpdateUserProfile] = useState<number>(0);
  const [userProfile, setUserProfile] = useState<any>(profile);
  const supabase = supabaseClient();
  const mobileDashboardView = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  const previewSideBySideView = useMediaQuery({
    query: "(min-width: 740px)",
  });

  const updateUserFirstVisitStatus = (status: boolean) => {
    userFirstDashboardLoadDBUpdate(
      supabase,
      status,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
  };

  useEffect(() => {
    setUserProfile(profile);
  }, [profile]);

  const getUserData = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select()
      .eq("id", profile?.id)
      .single();
    setUserProfile(JSON.parse(JSON.stringify(data)));
    sendDataToIframe(JSON.parse(JSON.stringify(data)));
  };

  useEffect(() => {
    if (updateUserProfile) {
      getUserData();
    }
  }, [updateUserProfile]);

  const FirstTimeLeadGenModal = () => (
    <Modal
      open={openLeadGenModal}
      onClose={() => {
        setOpenLeadGenModal(false);
        updateUserFirstVisitStatus(false);
      }}
    >
      <div className={"lead-gen-modal"}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={() => {
              setOpenLeadGenModal(false);
              updateUserFirstVisitStatus(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Would you like CRT to reach out to help your business with lead
          generation?
        </Typography>
        <div className={"lead-gen-modal-buttons"}>
          <Button
            onClick={() => {
              userWantsLeadGenCallDBUpdate(
                supabase,
                true,
                profile,
                setUpdateUserProfile,
                updateUserProfile
              );
              setOpenLeadGenModal(false);
              updateUserFirstVisitStatus(false);
            }}
            variant="contained"
          >
            Yes Please!
          </Button>
          <Button
            onClick={() => {
              setOpenLeadGenModal(false);
              updateUserFirstVisitStatus(false);
            }}
            variant="outlined"
          >
            No Thanks
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",

        width: "100%",
        height: "100vh",
      }}
    >
      <DashboardHelpModal open={openHelpModal} setOpen={setOpenHelpModal} />
      <FirstTimeLeadGenModal />
      <DashboardPreviewModal
        open={openPreviewModal}
        setOpen={setOpenPreviewModal}
        profile={userProfile}
      />
      {!previewSideBySideView && (
        <div style={{ height: "56px", minHeight: "56px" }}> </div>
      )}
      <div
        style={{
          position: !previewSideBySideView ? "fixed" : "relative",
          width: "100%",
          zIndex: "50",
        }}
      >
        {!mobileDashboardView && (
          <div className="logout-button" style={{ zIndex: 99 }}>
            <div style={{ display: "flex", gap: "20px" }}>
              <Button
                onClick={() => {
                  setOpenHelpModal(true);
                }}
                variant="text"
                style={{ color: "white" }}
              >
                Help
              </Button>
              <Logout className={"logout-button-white"} />
            </div>
          </div>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar style={{ justifyContent: "space-between" }}>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                style={{ paddingTop: "10px" }}
              >
                Website Dashboard
              </Typography>
              {mobileDashboardView && (
                <MobileNavigationHamberger
                  setSelectedTab={setSelectedTab}
                  setOpenModal={setOpenHelpModal}
                />
              )}
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <Box
        sx={{
          bgcolor: "white",
          padding: "0px",
        }}
      >
        <div style={{ padding: "10px 10px" }}>
          <Typography
            className={"first-time-login-page-subheader"}
            gutterBottom
            align="center"
          >
            Website changes are made live every business day at 5pm CST.
          </Typography>
          <Typography
            className={"first-time-login-page-subheader"}
            gutterBottom
            align="center"
          >
            {"live website: "}
            {profile.user_website_domain?.length > 1 ? (
              <Link target="_blank" href={`${profile.user_website_domain}`}>
                {profile.user_website_domain.split("https://")[1]}
              </Link>
            ) : (
              "website not ready yet"
            )}
          </Typography>
        </div>
        {!mobileDashboardView && (
          <>{dashboardTabs(selectedTab, handleTabChange)}</>
        )}
        <Divider />
        <div className="dashboard-field-container">
          <div
            style={{
              maxHeight: "100%",
              overflow: "scroll",
              width: "100%",
              padding: "0px",
            }}
          >
            {selectedTab === TabValues.SERVICE_INFO && (
              <ServicesPage
                profile={userProfile}
                setUpdateUserProfile={setUpdateUserProfile}
                updateUserProfile={updateUserProfile}
                setOpenPreviewModal={setOpenPreviewModal}
              />
            )}
            {selectedTab === TabValues.BUSINESS_INFO && (
              <BusinessInfoPage
                profile={userProfile}
                setUpdateUserProfile={setUpdateUserProfile}
                updateUserProfile={updateUserProfile}
                setOpenPreviewModal={setOpenPreviewModal}
              />
            )}
            {selectedTab === TabValues.CONTACT_INFO && (
              <BusinessContactInfoPage
                profile={userProfile}
                setUpdateUserProfile={setUpdateUserProfile}
                updateUserProfile={updateUserProfile}
                setOpenPreviewModal={setOpenPreviewModal}
              />
            )}
            {selectedTab === TabValues.ABOUT_INFO && (
              <AboutInfoPage
                profile={userProfile}
                setUpdateUserProfile={setUpdateUserProfile}
                updateUserProfile={updateUserProfile}
                setOpenPreviewModal={setOpenPreviewModal}
              />
            )}
            {selectedTab === TabValues.GENERAL_INFO && (
              <GeneralSiteSettingsPage
                profile={userProfile}
                setUpdateUserProfile={setUpdateUserProfile}
                updateUserProfile={updateUserProfile}
                setOpenPreviewModal={setOpenPreviewModal}
              />
            )}
            {selectedTab === TabValues.TOOLS_PLUGINS && (
              <PluginsAndToolsPage
                profile={userProfile}
                setUpdateUserProfile={setUpdateUserProfile}
                updateUserProfile={updateUserProfile}
                setOpenPreviewModal={setOpenPreviewModal}
              />
            )}
          </div>
          {previewSideBySideView && (
            <DashboardPreviewContainer profile={userProfile} />
          )}
        </div>
      </Box>
    </div>
  );
};
