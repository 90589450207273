import { useState, useEffect } from "react";
import supabaseClient from "../supabaseClient/supabaseClient";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { MoreInfoOnClick } from "./components/PageDisplayHelpers";
import { LinearProgressBar } from "./components/pageUpdateHelpers";
import { userWantsWebsiteReviewDBUpdate } from "../SharedComponents/SingleFieldDBUpdateFunctions";
export const ServiceAssistancePage = ({
  profile,
  setUpdateUserProfile,
  updateUserProfile,
  setPageSelect,
  pageSelect,
}: any) => {
  const supabase = supabaseClient();

  const onSubmit = async (serviceReviewBoolean: boolean) => {
    userWantsWebsiteReviewDBUpdate(
      supabase,
      serviceReviewBoolean,
      profile,
      setUpdateUserProfile,
      updateUserProfile
    );
    setPageSelect(pageSelect + 1);
  };

  const DoYouWantHelpWithServicesQuestion = () => (
    <>
      <Typography variant="body1" gutterBottom align="left">
        <p>Would you like help with your website content?</p>
      </Typography>
      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit(true);
          }}
        >
          yes
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit(false);
          }}
        >
          no
        </Button>
      </div>
    </>
  );

  return (
    <div className="flexColumn" style={{ width: "100%", maxWidth: "600px" }}>
      <LinearProgressBar pageSelect={pageSelect} />
      <Typography
        className={"first-time-login-page-title"}
        gutterBottom
        align="left"
      >
        Do you want help with your website content?
      </Typography>
      <Typography
        className={"first-time-login-page-subheader"}
        gutterBottom
        align="left"
      >
        Good website content is important for visibility, encourages user
        engagement, and makes your website look professional. CRT Digital can
        help write your service descriptions, about page, and other website data
        for free, click yes if you want CRT to help write your content.
      </Typography>
      {DoYouWantHelpWithServicesQuestion()}
    </div>
  );
};
