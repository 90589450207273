import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { PagePreviewDisplay } from "../../FirstTimeLoginFlow/components/PageDisplayHelpers";

export const CardDisplay = ({
  imageSrc,
  mobileDashboardView,
  children,
}: any) => {
  return (
    <Card
      sx={{
        minWidth: 275,
        boxShadow: 12,
        marginTop: "20px",
        padding: "5px",
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <div
            className="flexColumn"
            style={{
              gap: "10px",
              maxWidth: "600px",
              width: "100%",
              minWidth: "290px",
              paddingRight: "5px",
            }}
          >
            {children}
          </div>
          {/* {!mobileDashboardView && imageSrc && (
            <PagePreviewDisplay imageSrc={imageSrc} />
          )} */}
        </div>
      </CardContent>
    </Card>
  );
};
