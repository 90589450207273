import { useState, useEffect } from "react";
import { FirstTimeFlowSplash } from "./FirstTimeFlowSplash";
import { BusinessNamePage } from "./BusinessNamePage";
import supabaseClient from "../supabaseClient/supabaseClient";
import { NextButton } from "./components/pageUpdateHelpers";
import { ContactInformationPage } from "./ContactInformationPage";
import { BusinessAddressesPage } from "./BusinessAddressesPage";
import { BusinessHoursPage } from "./BusinessHoursPage";
import { BusinessServiceAreaPage } from "./BusinessServiceAreas";
import { BusinessServicesPage } from "./BusinessServicesPage";
import { BusinessSocialMedaiLinksPage } from "./BusinessSocialMediaLinksPage";
import { DomainInformationPage } from "./DomainInformationPage";
import { BackButton } from "./components/pageUpdateHelpers";
import { PaymentsPage } from "./PaymentsPage";
import { useMediaQuery } from "react-responsive";
import { ServiceAssistancePage } from "./ServiceAssistancePage";

export const FirstTimeLoginFlow = ({ profile }: { profile: any }) => {
  const [pageSelect, setPageSelect] = useState<number>(0);
  const supabase = supabaseClient();
  const [updateUserProfile, setUpdateUserProfile] = useState<number>(0);
  const [userProfile, setUserProfile] = useState<any>(profile);
  const displayPreviewImage = useMediaQuery({
    query: "(min-width: 800px)",
  });

  useEffect(() => {
    setUserProfile(profile);
  }, [profile]);

  const getUserData = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select()
      .eq("id", profile?.id)
      .single();
    setUserProfile({ ...data });
  };

  useEffect(() => {
    if (updateUserProfile) {
      getUserData();
    }
  }, [updateUserProfile]);

  const StepperPageLayout = ({ children }: any) => (
    <div className={"stepper-page-layout"}>
      <BackButton setPageSelect={setPageSelect} pageSelect={pageSelect} />
      <div className="stepper-form-section">{children}</div>
    </div>
  );

  const FormPagePropsWithPreviewImages = {
    profile: userProfile,
    setPageSelect: setPageSelect,
    pageSelect: pageSelect,
    setUpdateUserProfile: setUpdateUserProfile,
    updateUserProfile: updateUserProfile,
    displayPreviewImage: displayPreviewImage,
  };

  const PageSelect = () => {
    switch (pageSelect) {
      case 0:
        return (
          <FirstTimeFlowSplash
            setPageSelect={setPageSelect}
            pageSelect={pageSelect}
          />
        );
      case 1:
        return (
          <StepperPageLayout>
            <BusinessNamePage {...FormPagePropsWithPreviewImages} />
          </StepperPageLayout>
        );
      case 2:
        return (
          <StepperPageLayout>
            <ContactInformationPage {...FormPagePropsWithPreviewImages} />
          </StepperPageLayout>
        );
      case 3:
        return (
          <StepperPageLayout>
            <BusinessAddressesPage {...FormPagePropsWithPreviewImages} />
          </StepperPageLayout>
        );
      case 4:
        return (
          <StepperPageLayout>
            <BusinessHoursPage {...FormPagePropsWithPreviewImages} />
          </StepperPageLayout>
        );
      case 5:
        return (
          <StepperPageLayout>
            <BusinessServiceAreaPage {...FormPagePropsWithPreviewImages} />
          </StepperPageLayout>
        );
      case 6:
        return (
          <StepperPageLayout>
            <ServiceAssistancePage {...FormPagePropsWithPreviewImages} />
          </StepperPageLayout>
        );
      case 7:
        return (
          <StepperPageLayout>
            <BusinessServicesPage {...FormPagePropsWithPreviewImages} />
          </StepperPageLayout>
        );
      case 8:
        return (
          <StepperPageLayout>
            <BusinessSocialMedaiLinksPage {...FormPagePropsWithPreviewImages} />
          </StepperPageLayout>
        );
      case 9:
        return (
          <StepperPageLayout>
            <DomainInformationPage
              profile={userProfile}
              setPageSelect={setPageSelect}
              pageSelect={pageSelect}
              setUpdateUserProfile={setUpdateUserProfile}
              updateUserProfile={updateUserProfile}
            />
          </StepperPageLayout>
        );
      case 10:
        return <PaymentsPage profile={userProfile} />;
      default:
        return (
          <StepperPageLayout>
            <p>went out of bounds on steps</p>;
            <NextButton setPageSelect={setPageSelect} pageSelect={pageSelect} />
            <BackButton setPageSelect={setPageSelect} pageSelect={pageSelect} />
          </StepperPageLayout>
        );
    }
  };

  return (
    <>
      <PageSelect />
    </>
  );
};
