import { useEffect, useState, useMemo } from "react";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { useImageCacheManager } from "./ImageCachingComponent";

export const PreviewImageComponent = ({
  profile,
  size = "250px",
  photoFileName,
  originalFileURL,
}: {
  profile: any;
  photoFileName: string;
  size?: string;
  originalFileURL: string;
}) => {
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const { getImageCacheManagerValue } = useImageCacheManager();
  const supabasePublicUrl =
    "https://bkaqugwsgyvokszdjwoz.supabase.co/storage/v1/object/public/userPhotos/";

  useEffect(() => {
    const imageCacheReference = getImageCacheManagerValue(photoFileName);
    if (originalFileURL) {
      if (imageCacheReference) {
        setPreviewImageUrl(
          `${supabasePublicUrl}${originalFileURL}?cacheset=${imageCacheReference}`
        );
      } else {
        setPreviewImageUrl(`${supabasePublicUrl}${originalFileURL}`);
      }
    }
  }, [profile]);

  const renderedImage = useMemo(() => {
    return (
      <div>
        {previewImageUrl && originalFileURL !== "" ? (
          <img
            src={previewImageUrl}
            alt="Preview Image"
            className="imageUpload image"
            style={{ height: size }}
          />
        ) : (
          <div
            className="imageUpload no-image"
            style={{ height: size, width: "140px" }}
          >
            <ImageNotSupportedIcon
              style={{ color: "#E6E6E6", fontSize: "80px" }}
            />
          </div>
        )}
      </div>
    );
  }, [previewImageUrl, originalFileURL]);

  return <>{renderedImage}</>;
};
